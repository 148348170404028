/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .contact-section-one {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
      url("../images/new-contact-bg.webp");
    background-size: cover;
    background-position: center;
  }
  .error-page-banner img {
    width: 50%;
    height: auto;
    margin: auto;
  }
  .chemicalmanufacturing-new-design-2 .nav-pills .nav-link.active,
  .chemicalmanufacturing-new-design-2 .nav-pills .show > .nav-link {
    background-color: rgb(44, 44, 44);
  }
  .chemicalmanu-new-image-2 {
    width: 40px !important;
    height: auto !important;
    margin-bottom: 10px;
  }
  .chemicalmanu-new-image-2-1 {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
  }
  .chemicalmanu-new-text-1 {
    font-size: 14px;
  }
  .chemicalmanufacturing-new-design-2-inner-2 .card {
    background-color: transparent;
    color: #fff;
    border: 1px solid #2b2b2b57;
    cursor: pointer;
    padding: 0px;
  }
  .chemicalmanufacturing-new-design-2-inner-2 .card:hover {
    background-color: #0f0e0e75;
    border: 1px solid #2b2b2b;
  }
  .chemicalmanufacturing-new-design-2-inner-2 .card:active {
    background-color: #0f0e0e75;
    border: 1px solid #2b2b2b;
  }
  .chemicalmanufacturing-new-design-2 .swiper-slide-card .card {
    background-color: #0e0e0e9c;
    color: #fff;
  }
  .chemicalmanufacturing-new-design-2 .swiper-slide-card .card {
    background-color: #0e0e0e9c;
    color: #fff;
  }
  .chemicalmanufacturing-top-new-card-1 {
    background: linear-gradient(
      25deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0) 71%,
      #FFF 100%
    );
    padding-right: 2px;
    padding-left: 0px;
    padding-top: 0px;
  }
  .chemicalmanufacturing-top-new-card-2 {
    background: linear-gradient(
      155deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0) 71%,
      #FFF 100%
    );
    padding-bottom: 2px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .chemicalmanufacturing-top-new-card-3 {
    background: linear-gradient(
      -25deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0) 71%,
      #FFF 100%
    );
    padding-top: 2px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .chemicalmanufacturing-top-new-card-4 {
    background: linear-gradient(
      205deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0) 71%,
      #FFF 100%
    );
    padding-left: 2px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .chemicalmanufacturing-new-design-1 .card {
    background-color: #000 !important;
    border-radius: 0px;
    border: none !important;
  }
  .industrialmanu-top-new-card-1 {
    border-bottom: 1px solid #ffffff3b;
    border-right: 1px solid #ffffff3b;
    background: linear-gradient(
      155deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0.5438550420168067) 31%,
      rgba(209, 209, 209, 0.062) 100%
    );
  }
  .industrialmanu-top-new-card-4 {
    border-top: 1px solid #ffffff3b;
    border-left: 1px solid #ffffff3b;
    position: relative;
    top: -1px;
    left: -1px;
    background: linear-gradient(
      -20deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0.5438550420168067) 31%,
      rgba(209, 209, 209, 0.062) 100%
    );
  }
  .industrialmanu-new-design-2 .col:hover {
    background: linear-gradient(
      115deg,
      rgba(209, 209, 209, 0.062) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 31%,
      rgba(0, 0, 0, 0.233) 61%,
      rgba(209, 209, 209, 0.062) 100%
    );
  }
  .industrialmanu-icon-1 {
    width: 60px;
    height: auto;
    margin-bottom: 20px;
  }
  .industrialmanu-new-card-6 {
    border-top: 1px solid #ffffff3b;
    border-right: 1px solid #ffffff3b;
    border-bottom: 1px solid #ffffff3b;
    border-bottom-right-radius: 40px;
    border-top-right-radius: 40px;
  }
  .industrialmanu-new-card-5 {
    border: 1px solid #ffffff3b;
  }
  .industrialmanu-new-card-4 {
    border-top: 1px solid #ffffff3b;
    border-left: 1px solid #ffffff3b;
    border-bottom: 1px solid #ffffff3b;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
  }
  .industrialmanu-new-card-3 {
    border-top: 1px solid #ffffff3b;
    border-right: 1px solid #ffffff3b;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }
  .industrialmanu-new-card-2 {
    border-top: 1px solid #ffffff3b;
    border-left: 1px solid #ffffff3b;
    border-right: 1px solid #ffffff3b;
  }
  .industrialmanu-new-card-1 {
    border-top: 1px solid #ffffff3b;
    border-left: 1px solid #ffffff3b;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }
  .industrialmanu-new-design-2 .card {
    background-color: transparent;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
  }
  .automotive-new-text-2 {
    font-size: 80px;
    box-shadow: inset 0 0 15px 5px rgba(255, 255, 255, 0.204),
      inset 0 2px 1px 1px rgba(0, 0, 0, 0.9),
      inset 0 -2px 1px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
  }
  .automotive-new-text-1 {
    font-size: 20px;
  }
  .automotive-new-text-3 {
    font-size: 16px;
    opacity: 0.8;
  }
  .automotive-new-design-2 .card {
    background-color: transparent;
    color: #fff;
    border: none;
    /* box-shadow: inset 0 0 35px 5px #ff3d05, inset 0 2px 1px 1px rgba(0, 0, 0, 0.9), inset 0 -2px 1px rgba(0,0,0,0.25); */
    padding: 20px;
    transition: 0.2s;
    border-radius: 20px;
  }
  .automotive-new-design-2 .card:hover {
    box-shadow: inset 0 0 25px 5px rgba(255, 255, 255, 0.204),
      inset 0 2px 1px 1px rgba(0, 0, 0, 0.9),
      inset 0 -2px 1px rgba(0, 0, 0, 0.25);
  }
  .aerospace-new-text-6 {
    font-size: 20px;
    font-weight: 500;
  }
  .aerospace-new-text-5 {
    font-size: 18px;
    opacity: 0.8;
  }
  .aerospace-new-image-2 {
    width: 90px;
    height: auto;
    margin-bottom: 10px;
  }
  .aerospace-new-design-2-inner-1 {
    border-top: 1px solid #fffefe0a;
    border-left: 1px solid #fffefe0a;
    padding: 1%;
    background: linear-gradient(
      180deg,
      rgba(254, 254, 254, 0.027) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 92%,
      rgba(0, 212, 255, 0) 100%
    );
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .aerospace-new-design-2-inner-2 {
    border-bottom: 1px solid #fffefe0a;
    border-left: 1px solid #fffefe0a;
    border-right: 1px solid #fffefe0a;
    padding: 1%;
    padding-top: 4%;
    padding-bottom: 4%;
    background: linear-gradient(
      0deg,
      rgba(254, 254, 254, 0.027) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 92%,
      rgba(0, 212, 255, 0) 100%
    );
  }
  .aerospace-new-design-2-inner-3 {
    border-top: 1px solid #fffefe0a;
    border-right: 1px solid #fffefe0a;
    padding: 1%;
    background: linear-gradient(
      180deg,
      rgba(254, 254, 254, 0.027) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 92%,
      rgba(0, 212, 255, 0) 100%
    );
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .aerospace-new-design-1 {
    background-image: url("../../Assets/images/pattern/square-pattern.webp");
    background-size: 100% 100%;
  }
  .aerospace-new-image-1 {
    width: 100px;
    height: auto;
  }
  .aerospace-new-text-1 {
    font-size: 2.5vw;
    line-height: 4vw;
    font-weight: 400;
  }
  .aerospace-new-text-2 {
    font-size: 16px;
    opacity: 0.8;
    line-height: 30px;
  }
  .aerospace-new-text-3 {
    font-size: 1.4rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .aerospace-new-text-4 {
    font-size: 14px;
    opacity: 0.8;
  }
}
/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {
  .contact-section-one {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
      url("../images/new-contact-bg.webp");
    background-size: cover;
    background-position: center;
  }
  .chemicalmanufacturing-new-design-2 .nav-pills .nav-link.active,
  .chemicalmanufacturing-new-design-2 .nav-pills .show > .nav-link {
    background-color: rgb(44, 44, 44);
  }
  .chemicalmanu-new-image-2 {
    width: 30px !important;
    height: auto !important;
    margin-bottom: 10px;
  }
  .chemicalmanu-new-image-2-1 {
    width: 30px !important;
    height: auto !important;
    margin-bottom: 10px !important;
  }
  .chemicalmanu-new-text-1 {
    font-size: 14px;
  }
  .chemicalmanufacturing-new-design-2-inner-2 .card {
    background-color: transparent;
    color: #fff;
    border: 1px solid #2b2b2b57;
    cursor: pointer;
    padding: 0px;
  }
  .chemicalmanufacturing-new-design-2-inner-2 .card:hover {
    background-color: #0f0e0e75;
    border: 1px solid #2b2b2b;
  }
  .chemicalmanufacturing-new-design-2-inner-2 .card:active {
    background-color: #0f0e0e75;
    border: 1px solid #2b2b2b;
  }
  .chemicalmanufacturing-new-design-2 .swiper-slide-card .card {
    background-color: #0e0e0e9c;
    color: #fff;
  }
  .chemicalmanufacturing-new-design-2 .swiper-slide-card .card {
    background-color: #0e0e0e9c;
    color: #fff;
  }

  .chemicalmanufacturing-new-design-1 .card {
    background-color: #000 !important;
    border-radius: 0px;
    border: none !important;
  }
  /* .industrialmanu-top-new-card-1 {
    border-bottom: 1px solid #ffffff3b;
    border-right: 1px solid #ffffff3b;
    background: linear-gradient(
      155deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0.5438550420168067) 31%,
      rgba(209, 209, 209, 0.062) 100%
    );
  } */
  /* .industrialmanu-top-new-card-4 {
    border-top: 1px solid #ffffff3b;
    border-left: 1px solid #ffffff3b;
    position: relative;
    top: -1px;
    left: -1px;
    background: linear-gradient(
      -20deg,
      #ff3b0500 0%,
      rgba(0, 0, 0, 0.5438550420168067) 31%,
      rgba(209, 209, 209, 0.062) 100%
    );
  } */
  .industrialmanu-new-design-2 .col:hover {
    background: linear-gradient(
      115deg,
      rgba(209, 209, 209, 0.062) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 31%,
      rgba(0, 0, 0, 0.233) 61%,
      rgba(209, 209, 209, 0.062) 100%
    );
  }
  .industrialmanu-icon-1 {
    width: 60px;
    height: auto;
    margin-bottom: 20px;
  }

  .industrialmanu-new-design-2 .card {
    background-color: transparent;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
  }
  .automotive-new-text-2 {
    font-size: 80px;
    box-shadow: inset 0 0 15px 5px rgba(255, 255, 255, 0.204),
      inset 0 2px 1px 1px rgba(0, 0, 0, 0.9),
      inset 0 -2px 1px rgba(0, 0, 0, 0.25);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
  }
  .automotive-new-text-1 {
    font-size: 20px;
  }
  .automotive-new-text-3 {
    font-size: 16px;
    opacity: 0.8;
  }
  .automotive-new-design-2 .card {
    background-color: transparent;
    color: #fff;
    border: none;
    /* box-shadow: inset 0 0 35px 5px #ff3d05, inset 0 2px 1px 1px rgba(0, 0, 0, 0.9), inset 0 -2px 1px rgba(0,0,0,0.25); */
    padding: 20px;
    transition: 0.2s;
    border-radius: 20px;
  }
  .automotive-new-design-2 .card:hover {
    box-shadow: inset 0 0 25px 5px rgba(255, 255, 255, 0.204),
      inset 0 2px 1px 1px rgba(0, 0, 0, 0.9),
      inset 0 -2px 1px rgba(0, 0, 0, 0.25);
  }
  .aerospace-new-text-6 {
    font-size: 18px;
  }
  .aerospace-new-text-5 {
    font-size: 18px;
    opacity: 0.8;
  }
  .aerospace-new-image-2 {
    width: 60px;
    height: auto;
    margin-bottom: 20px;
  }
  .aerospace-new-design-2-inner-1 {
    border-top: 1px solid #fffefe0a;
    border-left: 1px solid #fffefe0a;
    padding: 1%;
    background: linear-gradient(
      180deg,
      rgba(254, 254, 254, 0.027) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 92%,
      rgba(0, 212, 255, 0) 100%
    );
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .aerospace-new-design-2-inner-2 {
    border-bottom: 1px solid #fffefe0a;
    border-left: 1px solid #fffefe0a;
    border-right: 1px solid #fffefe0a;
    padding: 1%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(
      0deg,
      rgba(254, 254, 254, 0.027) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 92%,
      rgba(0, 212, 255, 0) 100%
    );
  }
  .aerospace-new-design-2-inner-3 {
    border-top: 1px solid #fffefe0a;
    border-right: 1px solid #fffefe0a;
    padding: 1%;
    background: linear-gradient(
      180deg,
      rgba(254, 254, 254, 0.027) 0%,
      rgba(0, 0, 0, 0.5438550420168067) 92%,
      rgba(0, 212, 255, 0) 100%
    );
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .aerospace-new-design-1 {
    /* background-image: url("../../Assets/images/pattern/square-pattern.webp");
    background-size: 100% 100%; */
  }
  .aerospace-new-image-1 {
    width: 90px;
    height: auto;
  }
  .aerospace-new-text-1 {
    font-size: 1.6rem;
    font-weight: 600;
  }
  .aerospace-new-text-2 {
    font-size: 16px;
    opacity: 0.8;
    line-height: 30px;
  }
  .aerospace-new-text-3 {
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .aerospace-new-text-4 {
    font-size: 14px;
    opacity: 0.8;
  }
}

/*Tablet Query*/
@media only screen and (min-width: 800px) and (max-width: 1100px) {
}
