@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap'); /* NEWWWWWW */
@import url("https://fonts.googleapis.com/css2?family=Sometype+Mono:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ysabeau+Office&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  scroll-behavior: smooth;
}

body {
  background: #000000;
  color: #ffffff;
  height: 100;
  font-family: "Montserrat", sans-serif;
}
img {
  width: 100%;
  height: 100%;
}

/* Track */
::-webkit-scrollbar-track {
  background: #000000;
}

::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #202020;
}

::-webkit-scrollbar{
  /* width: 10px; */
  background: #202020;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.navbar .megamenu {
  padding: 1rem;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.navbar-shrink-new-ups {
  background-color: #000 !important;
  border-bottom: 1px solid #ffffff13;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes moveanimation {
  0% {
    margin-left: 10px;
    margin-right: 20px;
  }
  50% {
    margin-right: 10px;
    margin-left: 20px;
    /* background-color: #ff3d05; */
    /* opacity: 0.8; */
  }
  100% {
    margin-left: 10px;
    margin-right: 20px;
  }
}
@keyframes swiperCatchAnimation {
  0% {
    opacity: 0;
    /* transform: translate3d(1px, 1px, 50px) rotate3d(3, 2, 2, 5deg); */
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes footerBG {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 25000rem 0px;
  }
}

/* ============ DESKTOP-VIEW ============  */

@media all and (min-width: 992px) {
  .services-new-top-inner-icon-1 {
    width: 80px !important;
    height: auto !important;
    margin-bottom: 14px;
  }
  .floating-form-text-2 {
    font-size: 1.3rem;
    font-family: "Ysabeau Office", sans-serif;
    font-weight: 600;
    opacity: 0.7;
  }
  .footer-phone-postioning {
    background-color: #fff;
    position: relative;
    left: -2.6vw;
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 6px;
  }
  .footer-phone-postioning img {
    width: 50px;
    height: auto;
  }
  .floating-form-text-1-2 {
    content: "";
    position: absolute;
    right: -1.5vw;
    z-index: -1;
    width: 4vw;
    height: 6%;
    bottom: 0px;
    background: #ff3b05ad;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  }
  .floating-form-text-1 {
    font-size: 2.6rem;
    font-weight: 1000;
  }
  .footer-new-bg-section-inner-2 {
    width: 80%;
    float: right;
    margin-right: -16%;
    background-color: #9c9a9a2a;
    -webkit-backdrop-filter: blur(10.4px);
    backdrop-filter: blur(10.4px);
    color: #fff;
    z-index: 99;
    position: relative;
    padding: 3vw 0vw;
    border-top: 8px solid #ff3b05ad !important;
    overflow: visible;
    padding-right: 3vw;
    padding-left: 1.3vw;
  }
  .contact-new-ups-button-2 {
    font-size: 26px;
    background: #fff;
    color: #000;
    padding: 1px 1px;
    border-radius: 50px;
    border: 0;
    margin-left: 14px;
  }
  .contact-new-ups-button-1 {
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    padding: 10px 18px;
    border-radius: 50px;
    border: 0;
    -webkit-backdrop-filter: blur(63.4px);
    backdrop-filter: blur(63.4px);
    background: #57575738;
    box-shadow: none;
    border: 1px solid #ffffff1c;
  }
  .footer-new-bg-section-inner-1 .form-floating>.form-control:not(:placeholder-shown)~label::after,
  .footer-new-bg-section-inner-1
    .form-floating
    > .form-control:focus
    ~ label::after {
    background-color: transparent;
    color: #fff;
  }
  .footer-new-bg-section-inner-1 .form-floating>.form-control:not(:placeholder-shown)~label,
  .footer-new-bg-section-inner-1
    .form-floating
    > .form-control-plaintext
    ~ label,
  .footer-new-bg-section-inner-1 .form-floating > .form-control:focus ~ label {
    color: #fff;
    opacity: 0.4;
  }
  .footer-new-bg-section-inner-1 .form-floating > .form-select ~ label::after {
    background-color: transparent;
    color: #fff;
  }
  .footer-new-bg-section-inner-1 .form-floating > .form-select ~ label {
    color: #fff;
    opacity: 0.4;
  }
  .footer-new-bg-section-inner-1 .form-floating > label {
    font-size: 0.85rem;
  }
  .footer-new-bg-section-inner-1 .form-control {
    -webkit-backdrop-filter: blur(4.4px);
    backdrop-filter: blur(4.4px);
    background: #3a3a3a1c;
    box-shadow: none;
    border: 1px solid #ffffff1c;
    color: #fff;
    
  }
  .footer-new-bg-section-inner-1 .form-select {
    background-image: url("../images/form-select-arrow.webp") !important;
  }
  .footer-new-bg-section-inner-1 option {
    color: #000;
    font-size: 15px;
  }
  .footer-new-bg-section-inner-1 .form-select {
    -webkit-backdrop-filter: blur(4.4px);
    backdrop-filter: blur(4.4px);
    background-color: #3a3a3a1c;
    box-shadow: none;
    border: 1px solid #ffffff1c;
    color: #fff;
    font-size: 13px;
    background-size: 12px 12px;
  }
  .footer-new-bg-section-inner-1 {
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 200px;
    padding-right: 50px;
    background-color: #000000c5;
  }
  .footer-new-bg-section {
    background-image: url("../images/footer-image.webp");
    background-size: cover;
    background-position: bottom;
  }
  .bottom-copy-right-text-2 {
    font-size: 1.6vw;
    opacity: 0.9;
  }
  .bottom-copy-right-text-1 {
    font-size: 12px;
    width: 80%;
  }
  .footer-new-ups-bottom-inner-3 {
    padding: 2vw 0vw 6vw 5vw;
    margin: 2vw 0vw 0vw 0vw;
  }
  .footer-typo-new-2 a:hover .footer-typo-new-2-icons {
    transform: rotate(0deg) translateX(0px);
    visibility: visible;
  }
  .footer-typo-new-2-icons {
    clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
    font-size: 15px;
    transform: rotate(90deg) translateX(0px);
    visibility: hidden;
    transition: 0.2s;
  }
  .footer-typo-head {
    font-size: 20px;
    font-weight: 800;
  }
  .footer-typo-new-2 a {
    font-size: 14px;
    width: fit-content;
  }
  .footer-typo-new-1 a {
    font-size: 20px;
    width: fit-content;
    font-weight:500;
  }
  .navbar-brand-footer {
    font-size: 2.2vw !important;
  }
  .footer-new-ups-bottom-inner-1 {
    padding: 4vw 0vw 0vw 5vw;
  }
  .footer-new-ups-bottom-inner-2 {
    padding: 4vw 0vw 0vw 5vw;
  }
  .footer-new-ups-looping-section {
    background-image: url("../images/waves-footer-svg-svg.webp");
    background-size: 220%;
    animation: 10000s linear 0s infinite normal none running footerBG;
  }
  .footer-new-ups-bottom-section {
    background-color: #fff;
    color: #000;
  }
  .top-banner-new-section-4 .swiper-button-prev,
  .top-banner-new-section-4 .swiper-button-next {
    top: auto !important;
    bottom: 0px;
    border: 1px solid #ff3d05;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ff3b050c;
  }
  .top-banner-new-section-4 .swiper-button-prev {
    left: auto !important;
    right: 50px !important;
  }
  .top-banner-new-section-4 .swiper-button-prev:after,
  .top-banner-new-section-4 .swiper-button-next:after {
    font-size: 12px !important;
    color: #fff;
    font-weight: 800;
  }
  .top-banner-new-section-4 .swiper {
    padding-bottom: 50px;
  }
  .services-new-top-inner-card-2 {
    background: rgba(46, 46, 46, 0);
    border: 1px solid rgba(180, 180, 180, 0.253);
    color: #fff;
  }
  .new-ups-text-6 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 800;
  }
  .new-ups-text-7 {
    font-size: 14px;
  }
  .top-banner-new-section-3
    .swiper-slide-active
    .services-new-top-inner-card-1 {
    animation-name: swiperCatchAnimation;
    /* animation-duration: 1.6s; */
    animation-fill-mode: both;
  }
  .top-banner-new-section-3 .swiper {
    overflow-x: visible;
  }
  .top-banner-new-section-3 .swiper-vertical > .swiper-pagination-bullets,
  .top-banner-new-section-3
    .swiper-pagination-vertical.swiper-pagination-bullets {
    right: var(--swiper-pagination-right, 25px);
    left: var(--swiper-pagination-left, auto);
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    right: -34px;
  }

  .top-banner-new-section-3 .swiper-pagination-bullet-active {
    color: var(--color-1);
    background-image: linear-gradient(
      45deg,
      rgb(246, 146, 89),
      rgb(241, 105, 117)
    );
    height: 100px !important;
    border-radius: 12px;
  }

  .top-banner-new-section-3 .swiper-pagination-bullet {
    width: 14px;
    background: #ff3d05;
    height: 14px;
  }
  .top-banner-new-section-3 {
    /* background-image: url("../images/cloud-banner-new.png"); */
  }
  .services-new-top-inner-card-1 {
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(46, 46, 46, 0.068);
    border: 1px solid rgba(36, 36, 36, 0.253);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .services-new-top-card-1 {
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(46, 46, 46, 0.199);
    border: 1px solid rgba(36, 36, 36, 0.212);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  .new-ups-head-1 {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .new-ups-text-5 {
    font-size: 13px;
  }
  .new-ups-text-4 {
    font-size: 15px;
    font-weight: 800;
  }
  .new-ups-button-icon-2 {
    font-size: 18px;
    color: #000;
    position: relative;
    top: -1px;
    margin-left: 4px;
  }
  .new-ups-button-icon-3 {
    border-right: 1px solid #00000071;
    padding-right: 16px;
  }
  .new-ups-button-2 {
    font-size: 14px;
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 20px;
  }
  .new-ups-text-3 {
    font-size: 1.2rem;
    width: 35vw;
    opacity: 0.9;
    text-align: justify;
  }
  .top-banner-new-section-2 {
    /* height: 30vh; */
    /* background: #030303; */
    display: flex;
    align-items: center;
    padding: 40px 0px;
  }
  .new-ups-button-1 {
    border: 1px solid #ffffff85;
    border-radius: 50px;
    padding: 16px 4px 16px 16px;
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.5s;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .new-ups-button-icon-1 {
    /* border: 1px solid #FFF;  */
    font-size: 26px;
    border-radius: 50px;
    position: relative;
    top: -1px;
    margin-left: 10px;
    margin-right: 20px;
    background-color: #ffffff;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-name: moveanimation;
    animation-name: moveanimation;
    animation-iteration-count: infinite;
    opacity: 0.8;
    color: #000;
  }
  .new-ups-button-1:hover .new-ups-button-icon-1 {
    margin-right: 0px;
    margin-left: 20px;
  }
  .top-banner-new-swiper .swiper-pagination {
    z-index: 999 !important;
  }
  .top-banner-new-swiper .swiper-pagination-bullet {
    background: #ffffff85 !important;
  }
  .top-banner-new-swiper .swiper-pagination-bullet-active {
    background-color: #ffffff18 !important;
    position: relative;
  }
  .top-banner-new-swiper .swiper-pagination-bullet-active::after {
    content: "";
    height: 8px;
    position: absolute;
    width: 120px;
    left: 0px;
    background-color: #ff3d05 !important;
    border-radius: 4px !important;
    -webkit-animation-duration: 5.8s;
    animation-duration: 5.8s;
    -webkit-animation-name: growDown2;
    animation-name: growDown2;
  }
  @keyframes growDown2 {
    0% {
      width: 0px;
      background-color: #ff3d05 !important;
    }
    100% {
      width: 120px;
      background-color: #ff3d05 !important;
    }
  }
  .top-banner-new-swiper .swiper-pagination-bullet {
    width: 120px;
    border-radius: 4px !important;
  }
  .upstox-top-new-navbar .navbar {
    background: rgba(0, 0, 0, 0.651);
  }
  .upstox-top-new-navbar .navbar-brand {
    opacity: 0.9;
    color: #fff;
  }
  .upstox-top-new-navbar .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; /* remove the gap so it doesn't close */
  }
  .upstox-top-new-navbar .navbar .has-megamenu {
    position: static !important;
  }
  .upstox-top-new-navbar .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
  .upstox-top-new-navbar .dropdown-menu {
    -webkit-backdrop-filter: blur(63.4px);
    backdrop-filter: blur(63.4px);
    background: #2b2b2b3d;
    min-height: 600px;
    opacity: 1;
    color: #fff;
    padding: 40px 5vw !important;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: growDown;
    animation-name: growDown;
  }
  .upstox-top-new-navbar .navbar .nav-link {
    font-size: 14px;
    opacity: 0.9;
    letter-spacing: 0.5px;
    color: #fff;
    font-weight: 500;
  }
  .new-navbar-icon-1 {
    color: #ff3d05;
    font-size: 24px !important;
  }
  .dropdown-item-new-nav {
    font-size: 13px;
    margin-bottom: 0.6rem;
  }
  .new-navbar-text-1 {
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 1px;
    border-bottom: 1px solid #ff3d058e;
    width: fit-content;
    margin-bottom: 14px;
  }
  .new-navbar-text-2 {
    font-size: 14px;
  }
  .new-navbar-icon-2 {
    color: #ff3d05;
    font-size: 12px;
    margin-right: 4px;
  }
  .new-mega-drop-images-1 {
    height: 80px;
    width: auto;
    margin-bottom: 20px;
  }
  .new-ups-text-1 {
    font-size: 1.3vw;
    font-weight: 600;
    line-height: 1.5;
    width: 42%;
  }
  .new-ups-text-2 {
    opacity: 0.7;
    font-weight: 400;
  }
  .swiper-slide-1-bg {
    background-image: url("../images/banner-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .swiper-slide-2-bg {
    background-image: url("../images/banner-2.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .swiper-slide-3-bg {
    background-image: url("../images/banner-3.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .swiper-slide-4-bg {
    background-image: url("../images/banner-4.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .top-new-ups-height-1 {
    height: 70vh;
  }
  .top-new-ups-fixed-postion-1 {
    background-color: #ff3d05;
    position: absolute;
    width: 6px;
    height: 20vh;
    top: 20vh;
  }
}

/* ============ MOBILE-VIEW ============ */

@media (max-width: 991px) {
  .services-new-top-inner-icon-1 {
    width: 80px !important;
    height: auto !important;
    margin-bottom: 14px;
  }
  .floating-form-text-2 {
    font-size: 1rem;
    font-family: "Ysabeau Office", sans-serif;
    font-weight: 600;
    opacity: 0.7;
  }
  .footer-phone-postioning {
    background-color: #fff;
    position: relative;
    /* left: -2.6vw; */
    border-radius: 4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 6px;
  }
  .footer-phone-postioning img {
    width: 50px;
    height: auto;
  }
  .floating-form-text-1-2 {
    content: "";
    position: absolute;
    right: -1.5vw;
    z-index: -1;
    width: 4vw;
    height: 6%;
    bottom: 0px;
    background: #ff3b05ad;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
  }
  .floating-form-text-1 {
    font-size: 1.5rem;
    font-weight: 1000;
  }
  .footer-new-bg-section-inner-2 {
    background-color: #9c9a9a2a;
    -webkit-backdrop-filter: blur(10.4px);
    backdrop-filter: blur(10.4px);
    color: #fff;
    z-index: 99;
    position: relative;
    padding: 5vw 2vw;
    border-left: 2px solid #ff3b05ad !important;
    overflow: visible;
  }
  .contact-new-ups-button-2 {
    font-size: 26px;
    background: #fff;
    color: #000;
    padding: 1px 1px;
    border-radius: 50px;
    border: 0;
    margin-left: 14px;
  }
  .contact-new-ups-button-1 {
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    padding: 10px 18px;
    border-radius: 50px;
    border: 0;
    -webkit-backdrop-filter: blur(63.4px);
    backdrop-filter: blur(63.4px);
    background: #57575738;
    box-shadow: none;
    border: 1px solid #ffffff1c;
  }
  .footer-new-bg-section-inner-1 .form-floating>.form-control:not(:placeholder-shown)~label::after,
  .footer-new-bg-section-inner-1
    .form-floating
    > .form-control:focus
    ~ label::after {
    background-color: transparent;
    color: #fff;
  }
  .footer-new-bg-section-inner-1 .form-floating>.form-control:not(:placeholder-shown)~label,
  .footer-new-bg-section-inner-1
    .form-floating
    > .form-control-plaintext
    ~ label,
  .footer-new-bg-section-inner-1 .form-floating > .form-control:focus ~ label {
    color: #fff;
    opacity: 0.4;
  }
  .footer-new-bg-section-inner-1 .form-floating > .form-select ~ label::after {
    background-color: transparent;
    color: #fff;
  }
  .footer-new-bg-section-inner-1 .form-floating > .form-select ~ label {
    color: #fff;
    opacity: 0.4;
  }
  .footer-new-bg-section-inner-1 .form-floating > label {
    font-size: 0.85rem;
  }
  .footer-new-bg-section-inner-1 .form-control {
    -webkit-backdrop-filter: blur(4.4px);
    backdrop-filter: blur(4.4px);
    background: #3a3a3a1c;
    box-shadow: none;
    border: 1px solid #ffffff1c;
    color: #fff;
  }
  .footer-new-bg-section-inner-1 .form-select {
    background-image: url("../images/form-select-arrow.webp") !important;
  }
  .footer-new-bg-section-inner-1 option {
    color: #000;
    font-size: 15px;
  }
  .footer-new-bg-section-inner-1 .form-select {
    -webkit-backdrop-filter: blur(4.4px);
    backdrop-filter: blur(4.4px);
    background-color: #3a3a3a1c;
    box-shadow: none;
    border: 1px solid #ffffff1c;
    color: #fff;
    font-size: 13px;
    background-size: 12px 12px;
  }
  .footer-new-bg-section-inner-1 {
   padding: 40px 10px;
    background-color: #000000c5;
  }
  .footer-new-bg-section {
    background-image: url("../images/footer-image.webp");
    background-size: cover;
    background-position: bottom;
  }
  .bottom-copy-right-text-2 {
    font-size: 1.5rem;
    opacity: 0.9;
  }
  .bottom-copy-right-text-1 {
    font-size: 12px;
  }
  .footer-new-ups-bottom-inner-3 {
    padding: 20px 0px;
    margin:20px 0px;
  }
  .footer-typo-new-2 a:hover .footer-typo-new-2-icons {
    transform: rotate(0deg) translateX(0px);
    visibility: visible;
  }
  .footer-typo-new-2-icons {
   display: none;
  }
  .footer-typo-head {
    font-size: 20px;
    font-weight: 800;
  }
  .footer-typo-new-2 a {
    font-size: 14px;
    width: fit-content;
  }
  .footer-typo-new-1 a {
    font-size: 20px;
    width: fit-content;
    font-weight: 500;
  }
  .navbar-brand-footer {
    font-size: 1.3rem !important;
  }
  .footer-new-ups-bottom-inner-1 {
    padding: 4vw 0vw 0vw 5vw;
  }
  .footer-new-ups-bottom-inner-2 {
    padding: 5vw 0vw 0vw 0vw;
  }
  .footer-new-ups-looping-section {
    background-image: url("../images/waves-footer-svg-svg.webp");
    background-size: 250%;
    animation: 10000s linear 0s infinite normal none running footerBG;
  }
  .footer-new-ups-bottom-section {
    background-color: #fff;
    color: #000;
  }
  .top-banner-new-section-4 .swiper-button-prev,
  .top-banner-new-section-4 .swiper-button-next {
    top: auto !important;
    bottom: 0px;
    border: 1px solid #ff3d05;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ff3b050c;
  }
  .top-banner-new-section-4 .swiper-button-prev {
    left: auto !important;
    right: 50px !important;
  }
  .top-banner-new-section-4 .swiper-button-prev:after,
  .top-banner-new-section-4 .swiper-button-next:after {
    font-size: 12px !important;
    color: #fff;
    font-weight: 800;
  }
  .top-banner-new-section-4 .swiper {
    padding-bottom: 50px;
  }
  .services-new-top-inner-card-2 {
    background: rgba(46, 46, 46, 0);
    border: 1px solid rgba(180, 180, 180, 0.253);
    color: #fff;
  }
  .new-ups-text-6 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 800;
  }
  .new-ups-text-7 {
    font-size: 14px;
  }
  .top-banner-new-section-3
    .swiper-slide-active
    .services-new-top-inner-card-1 {
    animation-name: swiperCatchAnimation;
    /* animation-duration: 1.6s; */
    animation-fill-mode: both;
  }
  .top-banner-new-section-3 .swiper {
    overflow-x: visible;
  }
  .top-banner-new-section-3 .swiper-vertical > .swiper-pagination-bullets,
  .top-banner-new-section-3
    .swiper-pagination-vertical.swiper-pagination-bullets {
    right: var(--swiper-pagination-right, 25px);
    left: var(--swiper-pagination-left, auto);
    top: 50%;
    transform: translate3d(0px, -50%, 0);
    right: -34px;
  }

  .top-banner-new-section-3 .swiper-pagination-bullet-active {
    color: var(--color-1);
    background-image: linear-gradient(
      45deg,
      rgb(246, 146, 89),
      rgb(241, 105, 117)
    );
    height: 100px !important;
    border-radius: 12px;
  }

  .top-banner-new-section-3 .swiper-pagination-bullet {
    width: 14px;
    background: #ff3d05;
    height: 14px;
  }
  .top-banner-new-section-3 {
    /* background-image: url("../images/cloud-banner-new.png"); */
  }
  .services-new-top-inner-card-1 {
    /* backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(5px); */
    background: rgba(58, 58, 58, 0.164);
    border: 1px solid rgba(36, 36, 36, 0.253);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: #fff;
  }
  .services-new-top-card-1 {
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(5px);
    background: rgba(46, 46, 46, 0.199);
    border: 1px solid rgba(36, 36, 36, 0.212);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  .new-ups-head-1 {
    font-size: 1.6rem;
    font-weight: 500;
  }
  .new-ups-text-5 {
    font-size: 13px;
  }
  .new-ups-text-4 {
    font-size: 15px;
    font-weight: 800;
  }
  .new-ups-button-icon-2 {
    font-size: 18px;
    color: #000;
    position: relative;
    top: -1px;
    margin-left: 4px;
  }
  .new-ups-button-icon-3 {
    border-right: 1px solid #00000071;
    padding-right: 16px;
  }
  .new-ups-button-2 {
    font-size: 14px;
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 20px;
  }
  .new-ups-text-3 {
    font-size: 1rem;
    opacity: 0.9;
    text-align: justify;
  }
  .top-banner-new-section-2 {
    /* height: 30vh; */
    /* background: #030303; */
    display: flex;
    align-items: center;
    padding: 20px 0px;
  }
  .new-ups-button-1 {
    border: 1px solid #ffffff85;
    border-radius: 50px;
    padding: 10px 3px 10px 10px;
    font-size: 14px;
    text-transform: uppercase;
    transition: 0.5s;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .new-ups-button-icon-1 {
    /* border: 1px solid #FFF;  */
    font-size: 26px;
    border-radius: 50px;
    position: relative;
    top: -1px;
    margin-left: 10px;
    margin-right: 20px;
    background-color: #ffffff;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-name: moveanimation;
    animation-name: moveanimation;
    animation-iteration-count: infinite;
    opacity: 0.8;
    color: #000;
  }
  .new-ups-button-1:hover .new-ups-button-icon-1 {
    margin-right: 0px;
    margin-left: 20px;
  }
  .top-banner-new-swiper .swiper-pagination {
    z-index: 999 !important;
  }
  .top-banner-new-swiper .swiper-pagination-bullet {
    background: #ffffff85 !important;
  }
  .top-banner-new-swiper .swiper-pagination-bullet-active {
    background-color: #ffffff18 !important;
    position: relative;
  }
  .top-banner-new-swiper .swiper-pagination-bullet-active::after {
    content: "";
    height: 8px;
    position: absolute;
    width: 8vw;
    left: 0px;
    background-color: #ff3d05 !important;
    border-radius: 4px !important;
    -webkit-animation-duration: 5.8s;
    animation-duration: 5.8s;
    -webkit-animation-name: growDown2;
    animation-name: growDown2;
  }
  @keyframes growDown2 {
    0% {
      width: 0px;
      background-color: #ff3d05 !important;
    }
    100% {
      width: 8vw;
      background-color: #ff3d05 !important;
    }
  }
  .top-banner-new-swiper .swiper-pagination-bullet {
    width: 8vw;
    height: 6px;
    border-radius: 4px !important;
  }
  .upstox-top-new-navbar .navbar {
    background: rgb(0, 0, 0);
  }
  .upstox-top-new-navbar .navbar-brand {
    opacity: 0.9;
    color: #fff;
  }

  .upstox-top-new-navbar .navbar .has-megamenu {
    position: static !important;
  }
  .upstox-top-new-navbar .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }
  .upstox-top-new-navbar .dropdown-menu {
    -webkit-backdrop-filter: blur(63.4px);
    backdrop-filter: blur(63.4px);
    background: #2b2b2bd8;
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
    opacity: 1;
    color: #fff;
    padding: 10px 2vw !important;
  }
  .upstox-top-new-navbar .navbar .nav-link {
    font-size: 14px;
    opacity: 0.9;
    letter-spacing: 0.5px;
    color: #fff;
  }
  .new-navbar-icon-1 {
    color: #ff3d05;
    font-size: 24px !important;
  }
  .dropdown-item-new-nav {
    font-size: 13px;
    margin-bottom: 0.6rem;
  }
  .new-navbar-text-1 {
    font-size: 1.4rem;
    font-weight: 900;
    letter-spacing: 1px;
    border-bottom: 1px solid #ff3d058e;
    width: fit-content;
    margin-bottom: 14px;
  }
  .new-navbar-text-2 {
    font-size: 14px;
    text-align: justify;
  }
  .new-navbar-icon-2 {
    color: #ff3d05;
    font-size: 12px;
    margin-right: 4px;
  }
  .new-ups-text-1 {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.5;
    width: 95%;
    margin-bottom: 50px;
  }
  .new-ups-text-2 {
    opacity: 0.7;
    font-weight: 400;
  }
  .swiper-slide-1-bg {
    background-image: url("../images/banner-1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .swiper-slide-2-bg {
    background-image: url("../images/banner-2.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .swiper-slide-3-bg {
    background-image: url("../images/banner-3.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .swiper-slide-4-bg {
    background-image: url("../images/banner-4.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .mobile-new-ups-bg-shade {
    background-color: #00000093;
  }
  .top-new-ups-height-1 {
    height: 65vh;
  }
  .top-new-ups-fixed-postion-1 {
    background-color: #ff3d05;
    position: absolute;
    width: 4px;
    height: 14vh;
    top: 24vh;
  }
  .navbar.fixed-top .navbar-collapse,
  .navbar.sticky-top .navbar-collapse {
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 10px;
  }
  .new-mega-drop-images-1 {
    display: none;
  }
  .navbar-toggler-icon {
    background-image: none;
    color: #fff;
    font-size: 1.25rem;
    width: auto;
    height: auto;
    position: relative;
    top: -2px;
    padding: 1px;
  }
  .navbar-toggler {
    border: 2px solid #fff;
    box-shadow: none !important;
    outline: none !important;
   
  }
}
 /*Tablet Query*/
 @media only screen and (min-width: 800px) and (max-width: 1100px) {
  .top-new-ups-height-1 {
    height: 55vh;
  }
 }
.services-new-top-inner-card-2-icon-1{
  color: #ff3b05cc;
  padding: 16px;
  font-size: 60px;
  border-radius: 10px;
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(139, 139, 139, 0.034);
  border: 1px solid rgba(179, 179, 179, 0.151);
}
/* ============Karthick============== */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-new-ups-bottom-inner-2{
    display: flex;
    gap: 50px;
    flex-direction: row-reverse;
    justify-content: left;
  }

}
/* ============Karthick End============== */
