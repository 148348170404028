.invalid-email {
  color: red;
}

.custom-flag-select {
  width: 600px;
}

.custom-flag-select-phone {
  padding: 0px;
  margin: 0px;
  appearance: none;
}

.ReactFlagsSelect-module_disabledBtn__3A4GF {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  color: white !important;
}

.footer-new-ups-bottom-form-section .react-tel-input .form-control {
  -webkit-backdrop-filter: blur(4.4px);
  backdrop-filter: blur(4.4px);
  background: #3a3a3a1c;
  box-shadow: none;
  border: 1px solid #ffffff1c;
  color: #fff;
  width: 100% !important;
  height: 58px;
}
.contact-section-two .react-tel-input .form-control {
    background: rgb(47 47 47 / 60%);
    color: white;
    border: 1px solid rgb(47 47 47 / 60%);
    border-radius: 0px;
    width: 100% !important;
    height: 50px;
  }

.react-tel-input .flag-dropdown {
  background: transparent !important;
  outline: none !important;
  border: none !important;
  appearance: none !important;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus{
    background: transparent !important; 
}
.react-tel-input .country-list{
    width: max-content;
    color: #000;
}

.react-tel-input:hover .flag-dropdown:hover {
  background: transparent !important;
  outline: none !important;
  border: none !important;
}
.react-tel-input .flag-dropdown.open .selected-flag{
    background: transparent;
}

.react-tel-input .selected-flag .arrow{
  border-top: 4px solid #FFF;
}
.react-tel-input .selected-flag .arrow.up{
  border-bottom: 4px solid #FFF;
}