/* Amirtha Starting */

/* ============ COMMON ============  */
.text-color {
  color: #ef6d35;
}

.aerospace-card-section .card {
  color: white;
  background: none;
  border: none;
}

.aerospace-card-section .container-card {
  position: relative;
  border: 2px solid transparent;
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  background-clip: padding-box;
  border-radius: 45px;
  padding: 15px;

  img {
    margin-bottom: 32px;
  }
}

/* .card:hover {
  .card-text-one {
    color: #ef6d35;
  }
} */

.bg-green-box,
.bg-white-box,
.bg-yellow-box,
.bg-blue-box {
  position: relative;
}

.bg-green-box::after,
.bg-white-box::after,
.bg-yellow-box::after,
.bg-blue-box::after {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  content: "";
  z-index: -1;
  border-radius: 45px;
}

.bg-green-box::after {
  background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
}

.bg-white-box::after {
  background: linear-gradient(71deg, #121013, #b0afb0, #121013);
}

.bg-yellow-box::after {
  background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
}

.bg-blue-box::after {
  background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
}

/* areospace-end */
.automotive-banner-section .card {
  border: 1px solid #db8c73;
  color: white;
  background-color: transparent;
}

/* automotive-end */
.insustrial-card-section .card {
  color: white;
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-color: #242221;
  border-radius: 12px;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(240 92 6 / 14%);
}

.insustrial-card-section .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 10%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.insustrial-card-section .industrial-icon {
  border-radius: 10px;
  color: #fff;
  font-size: 40px;
  padding: 5px;
}

/* industries end */

.ChemicalManufacturing-card-section .swiper-button-prev,
.ChemicalManufacturing-card-section .swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset);
  bottom: 45px !important;
  height: var(--swiper-navigation-size);
}

.ChemicalManufacturing-card-section .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 10px);
  left: 51% !important;
  border: 1px solid;
  border-radius: 20px;
  width: 40px;
  color: #ef6d35;
}

.ChemicalManufacturing-card-section .swiper-button-prev {
  left: auto;
  right: 50% !important;
  border: 1px solid;
  border-radius: 20px;
  width: 40px;
  color: #ef6d35;
}

.ChemicalManufacturing-card-section .swiper-button-prev:after,
.ChemicalManufacturing-card-section .swiper-button-next:after {
  font-size: 25px;
}

.ChemicalManufacturing-card-section .card {
  color: white;
  background-color: transparent;
  border: 1px solid #db8c73;
}

.ChemicalManufacturing-card-section .chemical-icon {
  background-image: linear-gradient(109.6deg, #e6a17f 11.2%, #fc4c02 91.1%);
  border-radius: 12px;
  color: #fff;
  font-size: 60px;
  padding: 10px;
}

/* chemical-end */
.mining-card-section .card {
  color: white;
  background-color: transparent;
  border: 1px solid #e78663;
}

.mining-card-section .mining-icon {
  border-bottom: 2px solid #f08e60;
  border-radius: 10px;
  border-top: 2px solid #df9679;
  color: #ef6d35;
  font-size: 50px;
  padding: 7px;
}

/* mining end */

.oilgas-card-section .blurcolor-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 5%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.oilgas-card-section .oil-icon {
  font-size: 50px;
  color: #db8c73;
}

/* oil end */
.healthcare-card-section .card {
  border-radius: 30px;
  color: white;
  --bs-card-bg: transparent;
  border: 1px solid #db8c73;
}

.healthcare-card-section .health-icon {
  font-size: 60px;
  color: white;
  border: 1px solid #ef6d35;
  border-radius: 50%;
  padding: 7px 7px;
  background: #ef6d35;
}

/* health end */

.insurance-card-section .card {
  border-radius: 30px;
  color: white;
  --bs-card-bg: transparent;
  border: 1px solid #db8c73;
}

.insurance-card-section .insurance-icon {
  color: #ef6d35;
  font-size: 45px;
}

.insurance-card-section .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 5%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

/* insurance end */

.lifescience-card-section .card {
  background-color: transparent;
  border: 1px solid #ef6d35;
  border-radius: 10px;
  color: white;
}

.lifescience-card-section .life-icon {
  background-color: #ef6d35;
  border: 1px solid #db8c73;
  border-radius: 10px;
  font-size: 20px;
  height: 60px;
  padding: 8px;
  -webkit-transform: skew(15deg);
  transform: skew(15deg);
  width: 70px;
}

.travelhospitality-card-section .card {
  color: white;
  background: transparent;
  border: 1px solid #db8c73;
}

.travelhospitality-card-section .travel-icon {
  background-color: #ef6d35;
  border: 1px solid white;
  border-radius: 25px;
  /* box-shadow: 0 8px 24px rgba(255,68,0,.397), 0 16px 56px rgba(255,68,0,.473), 0 24px 80px rgba(255,68,0,.466); */
  font-size: 50px;
  padding: 5px;
}

/* travel end */

.information-card-section .card {
  background: black;
  border: 3px solid #f08e60;
  border-radius: 30px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.information-card-section .info-icon {
  border-bottom: 2px solid #f08e60;
  border-radius: 25px;
  border-top: 2px solid #df9679;
  color: #ef6d35;
  font-size: 50px;
  padding: 7px;
}

/* info end */

.engineeringprocurement-card-section .procure-icon {
  background-color: #ef6d35;
  border: 1px solid #e6e0de;
  box-shadow: inset 0 0 0 2px #ee5c1e, 10px -10px 0 -3px #e86026;
  color: #f5efece5;
  font-size: 40px;
  padding: 5px;
  text-align: start;
}

.engineeringprocurement-card-section .card {
  background-color: transparent;
  color: white;
  border: 1px solid #f08e60;
}

.engineeringprocurement-card-section .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 5%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.engineeringprocurement-card-section .card:hover {
  .procure-icon {
    box-shadow: none;
  }
}

/* engineering end */
.communication-card-section .card {
  background-color: transparent;
  color: white;
  border: 1px solid #f08e60;
}

.communication-card-section .com-icon {
  font-size: 50px;
  color: #f08e60;
  border: 1px solid rgb(221, 162, 125);
  padding: 5px 7px;
  border-radius: 20px;
}

/* communication end */

.adas-card-section .card {
  color: white;
  background: none;
  border: none;
}

.adas-card-section .container-card {
  position: relative;
  border: 2px solid transparent;
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  background-clip: padding-box;
  border-radius: 45px;
  padding: 15px;

  img {
    margin-bottom: 32px;
  }
}

/* adas end */
.hightechnology-card-section .card {
  --bs-card-border-color: hsla(0, 3%, 38%, 0.342);
  --bs-card-border-width: 2px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: hsla(0, 0%, 4%, 0.089);
  border: 1px solid rgba(128, 128, 128, 0.556);
  border-radius: 0;
  border-radius: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  color: white;
  position: relative;
}

.hightechnology-card-section .high-icon {
  color: #f18157;
  font-size: 50px;
}

/* high-end */
.agriculture-card-section .card {
  --bs-card-bg: transparent;
  border: 1px solid hsla(0, 0%, 50%, 0.678);
  border-radius: 0;
  color: white;
}

.agriculture-card-section .topcard {
  border-bottom: 4px solid rgba(255, 68, 0, 0.685);
  margin-right: 150px;
  margin-top: -19px;
}

/* agriculture end */

.publicsector-card-section .card {
  background: black;
  color: white;
  border: 1px solid #f18157;
}

.publicsector-card-section .public-icon {
  background: #f56c36ef;
  border: 1px solid #bd7553ef;
  border-radius: 16px;
  font-size: 60px;
  padding: 8px;
}

/* public end */
.education-card-section .card {
  color: white;
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-color: #242221;
  border-radius: 12px;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(240 92 6 / 14%);
}

.education-card-section .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 5%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.education-card-section .edu-icon {
  border-right: 2px solid #f08e60;
  border-radius: 25px;
  border-left: 2px solid #df9679;
  color: #ef6d35;
  font-size: 50px;
  padding: 7px;
}

/* education end */

.wastemanagement-banner-section .card {
  background: black;
  color: white;
  border: 1px solid #ef6d35aa;
}

.wastemanagement-banner-section .waste-icon {
  font-size: 50px;
  color: #ef6d35;
}

.mediaentertainment-card-section .card {
  background: transparent;
  color: white;
  border: 1px solid #ef6d35aa;
}

.mediaentertainment-card-section .media-icon {
  background-color: #f5d020;
  background-image: linear-gradient(315deg, #f5d020, #f53803 74%);
  border-radius: 12px;
  color: #fff;
  font-size: 60px;
  padding: 5px;
}

/* media end */
.utilities-card-section .card {
  background: black;
  border: 1px solid #f08e60;
  border-radius: 30px;
  color: white;
}

.utilities-card-section .uti-icon {
  border-bottom: 2px solid #df9679;
  border-radius: 30px;
  border-top: 2px solid #ff4500;
  color: #e45724;
  font-size: 50px;
  padding: 7px;
}

/* utilities end */
.financial-card-section .card {
  background: black;
  color: white;
  border: 1px solid #f08e60;
  border-radius: 30px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.financial-card-section .fin-icon {
  border-bottom: 2px solid #df9679;
  border-radius: 30px;
  border: 1px solid #f08e60;
  border-radius: 14px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #ef6d35;
  font-size: 50px;
  padding: 7px;
}

/* financial end */
.privateequity-card-section .card {
  color: white;
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-color: #242221;
  border-radius: 12px;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(240 92 6 / 14%);
}

.privateequity-card-section .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 5%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.privateequity-card-section .private-icon {
  border: 1px solid hsla(0, 3%, 39%, 0.466);
  border-radius: 10px;
  box-shadow: 0.95px 0.95px 1.6px #f14c0a;
  color: #f14c0a;
  font-size: 60px;
  padding: 5px;
}

/* private end */
.professional-card-section .card {
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 45px;
  color: white;
}

.professional-card-section .profes-icon {
  border: 1px solid #db8c73;
  box-shadow: inset 0 30px 60px -12px rgba(0, 0, 0, 0.507),
    inset 0 18px 36px -18px rgba(0, 0, 0, 0.541);
  color: #ca582e;
  font-size: 50px;
  padding: 5px;
  border-radius: 30px;
}

/* profession end */
.retail-card-section .card {
  color: white;
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-color: #242221;
  border-radius: 12px;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(240 92 6 / 14%);
}

.retail-card-section .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 5%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.retail-card-section .edu-icon {
  border-right: 2px solid #f08e60;
  border-radius: 25px;
  border-left: 2px solid #df9679;
  color: #ef6d35;
  font-size: 50px;
  padding: 7px;
}

.retail-card-section .retail-icon {
  background: #f56c36ef;
  border: 1px solid #bd7553ef;
  border-radius: 16px;
  font-size: 60px;
  padding: 8px;
}

/* retail end */

.consumer-card-section .consumer-icon {
  border-radius: 10px;
  color: #fff;
  font-size: 40px;
  padding: 5px;
}

.consumer-card-section .card {
  color: white;
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-color: #242221;
  border-radius: 12px;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(240 92 6 / 14%);
}

.consumer-card-section .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 10%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

/* consumer end */

.logistic-card-section .card {
  background: var(--background-color-1);
  border: 3px solid #f08e60;
  border-radius: 30px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  color: var(--color-1);
}

.logistic-card-section .logi-icon {
  border-bottom: 2px solid #f08e60;
  border-radius: 10px;
  border-top: 2px solid #df9679;
  color: #e45724;
  font-size: 50px;
  padding: 7px;
}

/* industries end */

/* .career-banner-section-three{
  border: 1px solid gray;
} */

.career-banner-section-three .card {
  color: white;
  backdrop-filter: blur(10px);
  box-shadow: none;
  border-color: #242221;
  border-radius: 12px;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgb(240 92 6 / 14%);
}

.career-banner-section-three .blur-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 10%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.career-icon {
  font-size: 50px;
  color: #ef6d35;
}

/* career end */

.aboutus-section-four .card {
  background: transparent;
  color: white;
  border: 1px solid #db8c73;
  border-radius: 20px;
}

.about-text-one:hover {
  color: #ef6d35;
}

.sustainability-card-section .card {
  background-color: transparent;
  color: white;
}

.sustainability-card-section .sub-icon {
  font-size: 75px;
  border-radius: 50%;
  text-align: center;
  padding: 8px 11px;
}

.sustainability-card-section .card {
  background-color: #0e0e0e;
}

.sustainability-card-section .card-container {
  border: 1px solid rgba(81, 81, 81, 0.267);
  margin-top: -150px;
  border-radius: 4px;
  -webkit-backdrop-filter: blur(44.4px);
  backdrop-filter: blur(44.4px);
  background: #3a3a3a65;
}

.contact-section-two ::placeholder {
  color: rgb(200, 199, 199);
  opacity: .6;
  /* Firefox */
  padding-left: 3px;
  font-size: 13px;
}

.contact-section-two .form-label {
  color: rgb(255, 255, 255);
  font-size: 15px;
}

.contact-button {
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  padding: 10px 30px;
  border-radius: 50px;
  border: 0;
  -webkit-backdrop-filter: blur(63.4px);
  backdrop-filter: blur(63.4px);
  background: #57575738;
  box-shadow: none;
  border: 1px solid #ffffff1c;
}

.contact-section-two .form-control:focus {
  color: white;
  background-color: rgb(57, 56, 56);
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.top-column {
  background: rgb(10, 9, 9);
  padding: 10px 40px;
  box-shadow: inset 0px 0px 25px 0px #48474740;

}

.contact-icon {
  font-size: 50px;
  border: 2px solid #ef6d35;
  padding: 10px;
  border-radius: 50%;
  color: #ef6d35;
}

.contact-section-two .card {
  background: rgb(10, 9, 9);
  border-radius: 2px;
  box-shadow: inset 0px 0px 25px 0px #48474740;
  color: white;
  width: 60%;
}


/* ============ DESKTOP-VIEW ============  */
@media all and (min-width: 992px) {
  .career-banner-section-one {
    background-image: url("../images/about/carreer-main-banner-1.webp");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
  }

  .top-banner-section-overral-bg-section {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }

  .top-banner-section {
    height: 75vh;
    display: flex;
    align-items: center;
    background-image: linear-gradient(to right, rgb(0, 0, 0), transparent);
  }

  .top-text-one {
    font-size: 1.3vw;
    font-weight: 600;
    line-height: 1.5;
    width: 45%;
    text-transform: capitalize;
  }

  .top-text-two {
    font-size: 14px;
    line-height: 1.6;
    width: 50%;
    opacity: 0.8;
  }

  .top-banner-section-two .text-one {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
  }

  .card-text-one {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  }

  .card-text-two {
    font-size: 15px;
    line-height: 1.5;
    opacity: 0.8;
  }

  .top-button {
    width: 157px;
    height: 47px;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(227 146 84), rgb(255, 85, 0));
    letter-spacing: 0.7px;
    padding-left: 13px;
    font-size: 16px;
  }

  .top-icon {
    font-size: 40px;
    border: 1px solid rgb(255, 255, 255);
    padding: 5px 5px;
    border-radius: 50%;
    text-align: end;
    align-items: end;
    background: white;
    color: black;
    font-weight: 900;
    box-shadow: 0 5px 15px rgba(25, 25, 24, 0.71);
  }

  .last-text {
    font-size: 15px;
    text-align: center;
  }

  .aerospace-card-section .iconone {
    font-size: 50px;
    border: 1px solid;
    padding: 5px 5px;
    border-radius: 10px;
  }

  .automotive-banner-section .auto-icon {
    border: 1px solid #e78663;
    border-radius: 20px;
    box-shadow: inset 0 30px 60px 90px rgba(255, 85, 0, 0.644),
      inset 0 18px 36px -18px rgba(221, 119, 71, 0.993);
    color: #ebb5a0;
    font-size: 60px;
    padding: 5px;
  }

  /* .ChemicalManufacturing-card-section .swiper-slide {
    height: 470px;
  } */
  .ChemicalManufacturing-card-section .mySwiper {
    padding-bottom: 130px;
  }

  .oilgas-card-section .card {
    color: white;
    background: rgba(2, 2, 2, 0.226);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(85, 39, 20, 0.781);
  }

  .center-align {
    text-align: center;
  }

  .lifescience-card-section .icon-align {
    margin-top: -60px;
  }

  .adas-card-section .adas-icon {
    width: 60px;
    height: 60px;
    padding: 5px 5px;
    border-radius: 10px;
  }

  .hightechnology-card-section .blurcolor-container {
    background-color: #fc511d;
    border-radius: 50%;
    bottom: 5%;
    bottom: 20%;
    -webkit-filter: blur(15px);
    filter: blur(15px);
    height: 20%;
    left: 40%;
    opacity: 0.2;
    position: absolute;
    right: 30%;
    top: 10%;
    width: 20%;
    z-index: -2;
  }

  .education-card-section .top-card {
    margin-top: -100px;
  }

  .education-card-section {
    padding-top: 90px;
  }

  .retail-card-section .top-card {
    margin-top: -100px;
  }

  .retail-card-section {
    padding-top: 90px;
  }

  .career-text-one {
    font-size: 28px;
    font-weight: 600;
    line-height: 45px;
  }

  .career-text-two {
    font-size: 15px;
    opacity: 0.8;
  }

  .career-banner-section-one {
    height: 600px;
    display: flex;
    align-items: center;
  }

  .career-button {
    font-size: 20px;
    padding: 5px 14px;
    color: white;
    background: linear-gradient(to left, rgb(227 146 84), rgb(255, 85, 0));
    border: 1px solid rgb(227 146 84);
    border-radius: 12px;
    background-position: right top;
  }

  .career-icon-button {
    font-size: 46px;
    color: #ef6d35;
    border: 1px solid #db8c73;
    padding: 5px 4px;
    border-radius: 9px;
  }

  .aboutus-banner-section .text-width {
    padding-left: 160px;
    padding-right: 160px;
  }

  .about-text-one {
    font-size: 19px;
    font-weight: 600;
  }

  .automotive-img {
    background-image: url("../images/servies-images/automotive-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .aerospace-img {
    background-image: url("../images/servies-images/aerospace-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .industrial-img {
    background-image: url("../images/servies-images/industrial-manufacturing.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .chemical-img {
    background-image: url("../images/servies-images/chemical-lab.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .mining-img {
    background-image: url("../images/servies-images/mining-industry.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .oilgas-img {
    background-image: url("../images/servies-images/gas-and-fuel.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .sub-text-one {
    font-size: 20px;
    line-height: 1.6;
    width: 100%;
  }

  /* .sustainability-banner-section-one{
    height: 300px;
    display: flex;
    align-items: center;
  } */

  .contact-section-one {
    height: 320px;
    background-image: url("../images/contact-banner.webp");
    align-items: center;
    display: flex;
  }

  .contact-banner-section .top-row {
    margin-top: -145px;
  }

  .contact-text-one {
    font-size: 35px;
    color: white;

  }

  .card-box {
    /* margin-left: 50px; */
    background-color: #000000e7;
    height: 100%;
    width: 100%;
  }

  .box-column {
    color: #fff;
    background-image: url("../images/woman-contact.webp");
    background-size: cover;

  }

  .form-img {
    width: 400px;
    height: 200px;
  }

  .contact-banner-section .form-control {
    background: rgb(47 47 47 / 60%);
    color: white;
    border: 1px solid rgb(47 47 47 / 60%);
    border-radius: 0px;
    padding: 0.675rem 0.75rem;
    
  }

  .contact-banner-section .form-select {
    --bs-form-select-bg-img: url("../images/white-arrow.png");
    background-color: rgb(47 47 47 / 60%);
    color: white;
    border: 1px solid rgb(47 47 47 / 60%);
    border-radius: 0px;
    padding: 0.675rem 0.75rem;
    box-shadow: none;
    appearance: unset !important;
    opacity: 0.8;
  }

  .contact-banner-section .list-group-item {
    padding: 0px 3px;
    color: white;
    text-decoration: none;
    background-color: transparent;
    border: none;

  }
  .wastemanagement-section .waste-img-iconone{
    width: 130px;
    height: auto;
  }
  .blog-text-one {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.3;
    text-transform: capitalize;
  }
}

/* ============ MOBILE-VIEW ============  */
@media (max-width: 991px) {
  .top-banner-section-overral-bg-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .top-banner-section {
    height: 500px;
    display: flex;
    align-items: center;
  }

  .mobile-new-ups-bg-shade-inner {
    background-color: #080808c5;
    height: 500px;
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
  }

  .top-text-one {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }

  .top-text-two {
    font-size: 12px;
    line-height: 1.6;
    opacity: 0.8;
  }

  .top-banner-section-two .text-one {
    font-size: 12px;
    line-height: 1.5;
    text-align: justify;
  }

  .card-text-one {
    font-size: 17px;
    font-weight: 600;
    line-height: 25px;
  }

  .card-text-two {
    font-size: 12px;
    line-height: 1.5;
    opacity: 0.9;
  }

  .aerospace-card-section .iconone {
    font-size: 40px;
    border: 1px solid;
    padding: 5px 5px;
    border-radius: 10px;
  }

  /* .top-button{
        width: 100px;
        height: 30px;
        font-size: 10px;
        border-radius: 30px;
        border: none;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-weight: 600;
        cursor: pointer;
        color: white;
        background: linear-gradient(to left,rgb(227 146 84),rgb(255, 85, 0));
        letter-spacing: 0.7px;
    } */
  .top-button {
    width: 100px;
    height: 30px;
    font-size: 10px;
    border-radius: 30px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background: linear-gradient(to left, rgb(227 146 84), rgb(255, 85, 0));
    letter-spacing: 0.7px;
    padding-left: 13px;
  }

  .top-icon {
    font-size: 20px;
    border: 1px solid rgb(255, 255, 255);
    padding: 5px 5px;
    border-radius: 50%;
    text-align: end;
    align-items: end;
    background: white;
    color: black;
    font-weight: 900;
    box-shadow: 0 5px 15px rgba(25, 25, 24, 0.71);
  }

  .last-text {
    font-size: 13px;
    text-align: center;
  }

  .automotive-banner-section .auto-icon {
    border: 1px solid #e78663;
    border-radius: 20px;
    box-shadow: inset 0 30px 60px 90px rgba(255, 85, 0, 0.644),
      inset 0 18px 36px -18px rgba(221, 119, 71, 0.993);
    color: var(--color-7);
    font-size: 40px;
    padding: 7px;
  }

  .ChemicalManufacturing-card-section .mySwiper {
    padding-bottom: 100px;
  }

  .oilgas-card-section .card {
    color: white;
    background: rgba(2, 2, 2, 0.226);
    border: 1px solid rgba(85, 39, 20, 0.781);
  }

  .travelhospitality-card-section .card-body {
    padding: 0px;
  }

  .adas-card-section .adas-icon {
    width: 50px;
    height: 50px;
    padding: 5px 5px;
    border-radius: 10px;
  }

  .career-text-one {
    font-size: 15px;
    font-weight: 600;
  }

  .career-text-two {
    font-size: 10px;
    opacity: 0.8;
  }

  .career-banner-section-one {
    height: 300px;
    display: flex;
    align-items: center;
  }

  .career-button {
    font-size: 15px;
    padding: 5px 14px;
    color: white;
    background: linear-gradient(to left, rgb(227 146 84), rgb(255, 85, 0));
    border: 1px solid rgb(227 146 84);
    border-radius: 12px;
    background-position: right top;
  }

  .career-icon-button {
    font-size: 30px;
    color: #ef6d35;
    border: 1px solid #db8c73;
    padding: 5px 4px;
    border-radius: 9px;
  }

  .automotive-img {
    background-image: url("../images/servies-images/automotive-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .aerospace-img {
    background-image: url("../images/servies-images/aerospace-bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .industrial-img {
    background-image: url("../images/servies-images/industrial-manufacturing.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .chemical-img {
    background-image: url("../images/servies-images/chemical-lab.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .mining-img {
    background-image: url("../images/servies-images/mining-industry.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .oilgas-img {
    background-image: url("../images/servies-images/gas-and-fuel.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .sub-text-one {
    font-size: 15px;
    line-height: 1.6;
  }

  .contact-section-one {
    height: 200px;
    background-image: url("../images/contact-banner.webp");
    align-items: end;
    display: flex;
  }

  .contact-text-one {
    font-size: 20px;
    color: white;
  }

  .card-box {
    /* margin-left: 50px; */
    background-color: #000000f5;
    height: 100%;
    width: 100%;
  }

  .box-column {
    color: #fff;
    background-image: url("../images/woman-contact.webp");
    background-size: cover;

  }

  .form-img {
    width: 100%;
    height: 200px;
  }

  .contact-banner-section .form-control {
    background: rgb(47 47 47 / 60%);
    color: white;
    border: 1px solid rgb(47 47 47 / 60%);
    border-radius: 0px;
    padding: 0.675rem 0.75rem;
    font-size: 13px ;
  }

  .contact-banner-section .form-select {
    --bs-form-select-bg-img: url("../images/white-arrow.png");
    background-color: rgb(47 47 47 / 60%);
    color: white;
    border: 1px solid rgb(47 47 47 / 60%);
    border-radius: 0px;
    padding: 0.675rem 0.75rem;
    box-shadow: none;
    appearance: unset !important;
    opacity: 0.8;
  }

  .contact-banner-section .list-group-item {
    padding: 0px 3px;
    color: white;
    text-decoration: none;
    background-color: transparent;
    border: none;
  }
  .wastemanagement-section .waste-img-iconone{
    width: 90px;
    height: auto;
  }
  .blog-text-one {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
  }
}

/* Amirtha Finishing */

/******************** Karthick ****************/
/******************** Desktop View ****************/
@media all and (min-width: 992px) {
  .TelecomProductDevelopment .telecom-card {
    padding-top: 150px;
  }

  .telecom-card .top-card {
    margin-top: -150px;
  }

  .enterprisefuture-card .topcard {
    border-bottom: 1px solid #ef6d35bf;
    border-bottom-width: 4px;
    margin-right: 150px;
    margin-top: -26px;
  }

  .enterprisefuture-card .topcard-one {
    border-bottom: 1px solid #ef6d35bf;
    border-bottom-width: 4px;
    margin-right: 65px;
    margin-top: -26px;
    margin-left: 75px;
  }

  .smartcontract-card {
    padding-top: 200px;
  }

  .smartcontract-card .topcard {
    margin-top: -150px;
  }

  .smartcontract-card .smart-icon {
    font-size: 60px;
    padding: 5px 5px;
    border-radius: 12px;
    color: white;
    background-color: #ef6d35;
    background-image: linear-gradient(315deg, #f5d22088 0%, #ef6d35 74%);
  }

  .business-process-card {
    padding-top: 200px;
  }

  .business-process .topcard {
    margin-top: -150px;
  }

  .business-process-card .bp-icon {
    font-size: 52px;
    border: 1px solid #ef6d3561;
    border-radius: 30px;
    padding: 7px 7px;
    z-index: -22;
    background: var(--background-color-1);
    color: var(--color-1);
  }

  .integrated-section .integrated-card {
    padding-top: 150px;
  }

  .integrated-card .top-card {
    margin-top: -150px;
  }

  .sustainability-section .sustain-card {
    padding-top: 150px;
  }

  .sustain-card .top-card {
    margin-top: -150px;
  }
}

/******************** Desktop View End*************/
/******************** Mobile view *****************/
@media (max-width: 991px) {
  .smartcontract-card .smart-icon {
    font-size: 40px;
    padding: 5px 5px;
    border-radius: 12px;
    color: white;
    background-color: #ef6d35;
    background-image: linear-gradient(315deg, #f5d22088 0%, #ef6d35 74%);
  }

  .business-process-card .bp-icon {
    font-size: 35px;
    border: 1px solid #ef6d3561;
    border-radius: 30px;
    padding: 7px 7px;
    z-index: -22;
    background: var(--background-color-1);
    color: var(--color-1);
  }
}

/******************** Mobile view End*****************/
/******* Infrastructure and cloud service *****/
.infrastructure-card .top-card {
  border-top: 1px solid #ef6d35;
  border-top-width: 4px;
  margin-right: 150px;
  margin-top: -26px;
}

.infrastructure-card .card {
  border-radius: 0px;
  --bs-card-bg: transparent;
  color: var(--color-1);
  border: 1px solid rgba(128, 128, 128, 0.678);
}

/******* Infrastructure and cloud service End *****/
/********* Network services ****************/
.networkservices-card .network-card-border {
  border: 1px solid #ef6d352d;
  border-top-left-radius: 1em;
}

.networkservices-card .network-icon {
  text-align: left;
  font-size: 35px;
  box-shadow: rgb(237 84 23 / 71%) 0px 5px 15px;
  background-color: transparent;
  color: #ef6d35;
}

/********* Network services End****************/
/*************** TelecomProductDevelopment *************/
.telecom-card .card {
  background-color: var(--background-color-1);
  color: var(--color-1);
  border-radius: 20px;
  border: 1px solid rgba(206, 74, 34, 0.5);
}

.telecom-card .tele-icon {
  font-size: 50px;
  border: 1px solid #ef6d35;
  padding: 8px 8px;
  background: #ef6d35;
  border-radius: 16px;
}

/*************** TelecomProductDevelopment End*************/
/*************** cybersecurity*******************/
.cybersecurity-card .cyber-icon {
  font-size: 50px;
  border-top: 2px solid #ef6d358c;
  border-bottom: 2px solid #ef6d358c;
  border-radius: 10px;
  padding: 7px 7px;
  color: #ef6d35;
}

.cybersecurity-card .card {
  background: var(--background-color-1);
  color: var(--color-1);
  border: 3px solid #ef6d35bf;
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

/*************** cyber security End*******************/
/*************** Enterprise future services **********/
.enterprisefuture-card .card {
  border-radius: 0px;
  --bs-card-bg: transparent;
  color: var(--color-1);
  border: 1px solid rgba(128, 128, 128, 0.678);
}

.enterprisefuture-card .blurcolor-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 1%;
  right: 0%;
  height: 200%;
  top: 105%;
  bottom: 20%;
  background-color: #ef6d35;
  opacity: 0.2;
}

/*************** Enterprise future services End**********/
/**************** Intelligent Automation ****************/
.intelligent-card .top-border {
  border: 1px solid #ff450003;
  background-color: #ff440007;
  border-radius: 20px;
}

.intelligent-card .intelligent-icon {
  text-align: left;
  font-size: 35px;
  box-shadow: #ef6d35 0px 5px 15px;
  background-color: transparent;
  border-radius: 100%;
  color: #ef6d35;
  padding: 5px;
}

/**************** Intelligent Automation End****************/
/**************** Oracle *********************/
.oracle-card .card {
  background-color: var(--background-color-1);
  color: var(--color-1);
  border: 1px solid #ef6d357e;
}

.oracle-card .oracle-icon {
  font-size: 50px;
  color: #ef6d35;
}

/**************** Oracle End*********************/
/**************** MetaverseDevelopment ************/
.metaverse-card .blurcolor-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 1%;
  right: 0%;
  height: 200%;
  top: 105%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.metaverse-card .card {
  --bs-card-bg: transparent;
  color: var(--color-1);
  --bs-card-border-color: rgb(90, 86, 86);
  border-radius: 0px;
}

.metaverse-card .meta-icon {
  font-size: 50px;
  color: white;
  box-shadow: rgba(189, 70, 22, 0.35) 0px 5px 15px;
  background: rgba(228, 59, 17, 0.89);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

/**************** MetaverseDevelopment End************/
/**************** DAppandDeFi *******************/
.dapp-card .blurcolor-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 1%;
  right: 0%;
  height: 260%;
  top: 120%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.dapp-card .card {
  --bs-card-bg: transparent;
  color: var(--color-1);
  border: 1px solid #ef6d3588;
}

.dapp-card .dapp-icon {
  text-align: start;
  font-size: 40px;
  color: #f5efece5;
  border: 1px solid rgb(230, 224, 222);
  background-color: #ef6d35;
  padding: 5px 5px;
  box-shadow: rgb(245, 72, 3) 0px 0px 0px 2px inset,
    rgba(209, 60, 1, 0.952) 10px -10px 0px -3px;
}

/**************** DAppandDeFi End*******************/
/**************** SmartContractDevelopment **************/
.smartcontract-card .blurcolor-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 1%;
  right: 10%;
  height: 200%;
  top: 120%;
  bottom: 20%;
  background-color: #ef6d35;
  opacity: 0.2;
}

.smartcontract-card .card {
  --bs-card-bg: transparent;
  color: var(--color-1);
  border-radius: 20px;
  border-color: rgb(94, 90, 88);
}

/**************** SmartContractDevelopment End**************/
/**************** Blockchain *********************/
.blockchain-card .blurcolor-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 10%;
  bottom: 20%;
  background-color: #ef6d35c9;
  opacity: 0.2;
}

.blockchain-card .card {
  color: var(--color-1);
  border-color: #242221;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid #ef6d3560;
}

.blockchain-card .go-arrow {
  font-size: 36px;
  border: 1px solid rgba(255, 68, 0, 0.527);
  border-radius: 50%;
  text-align: center;
  color: #ef6d35;
  padding: 5px 5px;
}

.blockchain-card .block-icon {
  font-size: 40px;
  padding: 5px 5px;
  border-radius: 10px;
  color: white;
}

/**************** Blockchain End*********************/
/**************** ArtificialIntelligence *************/
.artificial-card .blurcolor-container {
  position: absolute;
  filter: blur(200px);
  z-index: -2;
  width: 90%;
  left: 10%;
  right: 20%;
  height: 90%;
  top: 10%;
  bottom: 20%;
  background-color: #db8c73;
  opacity: 0.2;
}

.artificial-card .card {
  color: var(--color-1);
  border-color: #242221;
  background: rgb(241 238 238 / 0%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgb(240 92 6 / 14%);
}

.artificial-card .artificial-icon {
  font-size: 40px;
  padding: 5px 5px;
  border-radius: 10px;
  color: #ef6d35;
}

/**************** ArtificialIntelligence End*************/
/**************** Digitalsupplychain **************/
.digital-card .top-border {
  border: 1px solid #ff450003;
  background-color: #ff440007;
  border-radius: 20px;
}

.digital-card .digital-icon {
  text-align: left;
  font-size: 35px;
  box-shadow: rgb(237 84 23 / 71%) 0px 5px 15px;
  background-color: transparent;
  border-radius: 100%;
  color: #ef6d35;
  padding: 5px;
}

/**************** Digitalsupplychain End**************/
/**************** Business process service ************/
.business-process-card .card {
  --bs-card-bg: transparent;
  color: white;
  --bs-card-border-color: #ef6d3561;
  border-radius: 15px;
}

/**************** Business process service End************/
/**************** BusinessExcellenceServices ***************/
.business-excellence-card .card {
  color: var(--color-1);
  --bs-card-border-color: rgba(100, 95, 95, 0.342);
  --bs-card-border-width: 2px;
  position: relative;
  background: rgba(10, 10, 10, 0.089);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(128, 128, 128, 0.295);
}

.business-excellence-card .blurcolor-container {
  position: absolute;
  filter: blur(15px);
  z-index: -2;
  width: 20%;
  left: 40%;
  right: 30%;
  height: 20%;
  top: 5%;
  bottom: 20%;
  background-color: #fc511d;
  opacity: 0.2;
  border-radius: 50%;
}

.business-excellence-card .business-icon {
  font-size: 50px;
  color: #ef6d35;
}

/**************** BusinessExcellenceServices End***************/
/******* Testing service *****/
.testing-card .top-card {
  border-top: 1px solid #ef6d35;
  border-top-width: 4px;
  margin-right: 150px;
  margin-top: -26px;
}

.testing-card .card {
  border-radius: 0px;
  --bs-card-bg: transparent;
  color: var(--color-1);
  border: 1px solid rgba(128, 128, 128, 0.678);
}

/******* Testing service End *****/
/*************** Integrated Engineering Solutions **************/
.integrated-card .card {
  background-color: var(--background-color-1);
  color: var(--color-1);
  border-radius: 20px;
  border: 1px solid rgba(206, 74, 34, 0.5);
}

.integrated-card .integrate-icon {
  font-size: 50px;
  border: 1px solid #ef6d35;
  padding: 8px 8px;
  background: #ef6d35;
  border-radius: 16px;
}

/*************** Integrated Engineering Solutions End**************/
/*************** Performance engineering *******************/
.performance-card .performance-icon {
  font-size: 50px;
  border-top: 2px solid #ef6d358c;
  border-bottom: 2px solid #ef6d358c;
  border-radius: 10px;
  padding: 7px 7px;
  color: #ef6d35;
}

.performance-card .card {
  background: var(--background-color-1);
  color: var(--color-1);
  border: 3px solid #ef6d35bf;
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

/*************** Performance engineering End*******************/
/********* experience design services ****************/
.experience-card .experience-card-border {
  border: 1px solid #ef6d352d;
  border-top-left-radius: 1em;
}

.experience-card .experience-icon {
  text-align: left;
  font-size: 35px;
  box-shadow: rgba(237, 84, 23, 0.315) 0px 5px 15px;
  background-color: transparent;
  color: #ef6d35;
}

/********* experience design services End****************/
/******* SAP *****/
.sap-card .top-card {
  border-top: 1px solid #ef6d35;
  border-top-width: 4px;
  margin-right: 150px;
  margin-top: -26px;
}

.sap-card .card {
  border-radius: 0px;
  --bs-card-bg: transparent;
  color: var(--color-1);
  border: 1px solid rgba(128, 128, 128, 0.678);
}

/******* SAP End *****/
/*************** DataAnalytics *******************/
.data-card .data-icon {
  font-size: 50px;
  border-top: 2px solid #ef6d358c;
  border-bottom: 2px solid #ef6d358c;
  border-radius: 10px;
  padding: 7px 7px;
  color: #ef6d35;
}

.data-card .card {
  background: var(--background-color-1);
  color: var(--color-1);
  border: 3px solid #ef6d35bf;
  border-radius: 30px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

/*************** DataAnalytics End*******************/
/********* CustomerExperience  ****************/
.customer-card .customer-card-border {
  border: 1px solid #ef6d352d;
  border-top-left-radius: 1em;
}

.customer-card .customer-icon {
  text-align: left;
  font-size: 35px;
  box-shadow: rgb(237 84 23 / 71%) 0px 5px 15px;
  background-color: transparent;
  color: #ef6d35;
}

/********* CustomerExperience End****************/
/*************** SustainabilityasaService *************/
.sustain-card .card {
  background-color: var(--background-color-1);
  color: var(--color-1);
  border-radius: 20px;
  border: 1px solid rgba(206, 74, 34, 0.5);
}

.sustain-card .sustain-icon {
  font-size: 50px;
  border: 1px solid #ef6d35;
  padding: 8px 8px;
  background: #ef6d35;
  border-radius: 16px;
}

/*************** SustainabilityasaService End*************/
/******************** Karthick End****************/
/*********** karthick responsive work ***********/
/*********** Desktop **************/
@media (min-width: 992px) {
  .sustainability-banner-section-one {
    height: 400px;
    display: flex;
    align-items: center;
    background-color: #0f0f11;
  }
}

/*********** Desktop End***********/
/************ Mobile *************/
@media (max-width: 992px) {
  .about-text-one {
    font-size: 17px;
    font-weight: 600;
  }

  .sustainability-banner-section-one {
    height: 400px;
    display: flex;
    align-items: center;
  }
}

/************ Mobile End ************/
/************ karthick responsive work End**********/





/*Tablet Query*/
@media only screen and (min-width: 800px) and (max-width: 1100px) {
  .top-banner-section {
    height: 55vh;
    display: flex;
    align-items: center;
  }

  .mobile-new-ups-bg-shade-inner {
    height: 55vh;
    display: flex;
    align-items: center;
  }

}

/* ================== Amirtha starting =================== */

/* COMMON */
.logistic-new-card-section .card {
  border-radius: 0px;
  color: white;
  background: transparent;
  border: none;
}

.logi-blur {
  position: absolute;
  filter: blur(145px);
  z-index: -3;
  width: 33%;
  left: 4%;
  right: 20%;
  height: 58%;
  top: 17%;
  bottom: 20%;
  background-color: #ff3d00bf;
  opacity: 0.6;
  border-radius: 50%;
}

.border-n-row {
  border: 1px solid rgb(54 52 52 / 71%);
  border-radius: 20px;
  background-image: linear-gradient(rgb(58 55 55 / 98%), rgb(0 0 0));
}

/* .logistic-last-section{
  background-image: linear-gradient( 109.6deg, rgb(0 0 0) 1.8%, rgb(189 113 34) 50.6%, rgb(0 0 0) 100.3% );
} */

.border-one-card {
  /* border-left: 3px solid #e36f1c; */

}

/* .logi-one-back{
background-image: url("../images/logi-background.png");
background-repeat: no-repeat;
background-position: left;
background-size: 32% 100%;
} */

.logi-icon-one {
  font-size: 60px;
  padding: 10px 15px;
  border-radius: 12px;
  color: rgb(255, 255, 255);
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.102);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

}

.icon-align-one {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(96deg, rgb(0 0 0) 1.8%, rgb(189 113 34 / 55%) 50.6%, rgb(0 0 0) 100.3%);
  border-image-slice: 1;
  width: 100%;

}

.card-width-one {
  width: 80%;
}

/* .logistic-new-card-section .card-one{
  border: 1px solid #0c0c0c5e;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.522);
} */
/* logistic end */
.consumer-tw-text {
  font-size: 30px;
}

.consumer-icon-one {
  font-size: 80px;
  border-radius: 50%;
  padding: 5px 20px;
}

.consumer-new-cards-section .card-one {
  background: transparent;
  border: 1px solid #363535;
  border-radius: 90px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  color: white;
}

.consumer-new-cards-section .card-two {
  background: transparent;
  border: 1px solid #2a2a2a;
  border-radius: 90px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  color: white;
}

.consumer-blur {
  position: absolute;
  filter: blur(200px);
  z-index: -3;
  width: 33%;
  left: 33%;
  height: 58%;
  top: 17%;
  bottom: 20%;
  background-color: #ff3d00bf;
  opacity: 0.6;
  border-radius: 50%;
}


/* retail start */



.card-re-one .card {
  background: transparent;
  color: white;
  border: 1px solid;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 30px 30px; */
  /* background-image: url("../images/waveretail.png"); */
}


.card-re-one .card-body {
  -webkit-backdrop-filter: blur(63.4px);
  backdrop-filter: blur(49.4px);
  /* background-color: #273f4b42; */
}

.re-icon-one {
  font-size: 50px;
  padding: 5px 18px;
}

.re-two-card {
  background-image: url("../imgs/2background-re.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.img-card-one {
  width: 70px;
  height: auto;
}

.re-card-three .card {
  color: white;
  background: transparent;
}

.retail-banner-section-three .card-back-re1 {
  background-image: url("../imgs/retail1.webp");
  background-size: 100% 100%;
}

.retail-banner-section-three .card-back-re2 {
  background-image: url("../imgs/retail2.webp");
  background-size: 100% 100%;
}

.retail-banner-section-three .card-back-re3 {
  background-image: url("../imgs/retail3.webp");
  background-size: 100% 100%;
}





.re-card-three .re-icon-three {
  font-size: 60px;
  padding: 8px 5px;
  border: 1px solid;
  border-radius: 50%;
}

.card-re-border-top {
  border: 1px solid rgba(30, 30, 30, 0.357);
  width: 90%;
  margin: auto;
  height: 30px;
  border-bottom: none;
}

.card-re-border-bottom {
  border: 2px solid;
  width: 90%;
  margin: auto;
  height: 30px;
  border-top: none;
  border-radius: 2px;
}

.th-icon-one {
  color: #ab1265;
  background: #c1418b3d;
  border-color: #a55d6a;
}

.th-icon-two {
  color: #672da3;
  background: #41b3c124;
  border-color: #a55d6a;
}

.th-icon-three {
  color: #c36804;
  background: #c17f4124;
}

/* retail end */
.con-card-two .card {
  color: white;
  background-image: radial-gradient(circle farthest-corner at 0.2% 0.5%, #000000 3.7%, #181d22 97.7%);
  border: none;
  border-radius: 10px;
}

.con-icon-top {
  margin-top: -50px;
  width: 88px;
  height: auto;
  border-top: 1px solid rgba(27, 169, 174, 0.65);
  border-right: 1px solid rgba(32, 162, 160, 0.748);
  padding: 20px 20px;
  border-radius: 50%;
  /* outline: black; */
  background-image: radial-gradient(circle farthest-corner at 0.2% 0.5%, #000000 30.7%, #181d22 70.7%);
  outline: #000000 solid 7px;
}

.contact-text-last {
  font-size: 20px;
  font-weight: 500;
}

.contact-text-section {
  border-top: 1px solid rgb(14, 14, 14);
}

@media all and (min-width: 992px) {

  /* DESKTOP VIEW*/
  .logistic-banner-section-two .logi-tw-text {
    font-size: 20px;
    width: 50%;
    line-height: 30px;
  }

  .logi-th-icon {
    font-size: 52px;
    margin-top: -31px;
    border: 1px solid #363636;
    background: #161313;
    padding: 5px 5px;
    border-radius: 11px;
  }

  .logi-img {
    width: 130%;
    height: auto;
    margin-top: -20%;
  }

  .logi-top-card {
    margin-top: -50px;
  }

  .retail-tw-text {
    width: 50%;
    font-size: 16px;
    line-height: 25px;
  }

  .retail-last-img {
    width: 500px;
    height: 400px;
  }

  .retail-card-one {
    margin-top: -100px;
  }

  .con-top {
    padding-top: 80px;
  }

  .re-two-card .card {
    background: rgba(255, 255, 255, 0.068);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.078);
    color: white;
    width: 50%;
  }

  .re-two-card .left-margin {
    margin-left: auto;
  }

  .logi-img-one {
    width: 80px;
    height: auto;
  }

  .logi-img-two {
    width: 70px;
    height: auto;
    margin-top: -50px;
    background: black;
  }

  .logi-icon-tick {
    padding: 5px 5px;
    font-size: 30px;
    color: #d17553;
  }

  .communication-banner-section .con-img-one {
    width: 70px;
    height: auto;
    margin-right: 14px;
  }
  .engineeringprocurement-banner-section .eng-img{
    width: 100px;
    height: auto;
  }
}

@media (max-width: 992px) {

  /* MOBILE VIEW */
  .logi-img {
    width: 50%;
    height: auto;
  }

  .logistic-banner-section-two .logi-tw-text {
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }

  .logi-th-icon {
    font-size: 52px;
    border: 1px solid #363636;
    background: #161313;
    padding: 5px 5px;
    border-radius: 11px;
  }

  .retail-tw-text {
    font-size: 16px;
    line-height: 25px;
  }

  .con-top-mobile {
    padding-top: 70px;
  }

  .re-two-card .card {
    background: rgba(255, 255, 255, 0.068);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.078);
    color: white;

  }

  .retail-last-img {
    width: 230px;
    height: auto;
  }

  .logi-img-one {
    width: 50px;
    height: auto;
  }

  .logi-icon-tick {
    font-size: 20px;
    color: #d17553;
  }

  .logi-img-two {
    width: 70px;
    height: auto;
    background: black;
  }

  .communication-banner-section .con-img-one {
    width: 65px;
    height: auto;
    margin-right: 14px;
  }
  .engineeringprocurement-banner-section .eng-img{
    width: 90px;
    height: auto;
  }
}

@media only screen and (min-width: 740px) and (max-width: 1100px) {
  .logi-img {
    width: 340%;
    height: auto;
  }

  .logi-th-icon {
    font-size: 52px;
    margin-top: -31px;
    border: 1px solid #363636;
    background: #161313;
    padding: 5px 5px;
    border-radius: 11px;
  }

  .retail-card-one {
    margin-top: -100px;
  }

  .re-two-card .left-margin {
    margin-left: auto;
  }

  .retail-tw-text {
    width: 50%;
  }

  .retail-card-one {
    margin-top: -100px;
  }

  .re-two-card .card {
    width: 80%;
  }

  .logi-img-two {
    width: 70px;
    height: auto;
    margin-top: -50px;
    background: black;
  }
  /* .aerospace-img {
    background-image: url("../images/servies-images/aerospace-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 75%;
  } */
  .back-posi-img {
    background-position: 75%;
  }
}

.top-border-pro {
  border: 3px solid rgba(46, 46, 46, 0.2);
}

.waste-border-card {
  border: 1px solid #70c3bb85;
}

/* ================== Amirtha Ending ===================== */



/*====================  Karthick =======================*/
/***********  karthick responsive ********/
/*********** Desktop **************/
@media (min-width: 992px) {
  .service-footer-text {
    font-size: 1.2rem;
    opacity: 0.9;
  }

  .sustaincard-section .text-one {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
  }

  .sustaincard-section .sustain-card-img {
    width: 75px;
    height: 75px;
  }

  .customercard-section .text-one {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
  }

  .customercard-section-three .card-text-one {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
  }

  .customercard-section .customer-card-img {
    width: 100%;
    height: 100%;
  }

  .datacard-section .text-one {
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: center;
  }

  .datacard-section-one .datacard-img {
    height: 100%;
    width: 100%;
  }

  .datacard-section-one .data-card-blur {
    top: 110%;
    left: 15%;
    width: 300px;
    height: 300px;
    filter: blur(200px);
    z-index: 0;
    position: absolute;
    border-radius: 50%;
  }

  .sustaincard-section-five .contact-text {
    font-size: 20px;
    font-weight: 500;
  }

  .customercard-section-five .contact-text {
    font-size: 20px;
    font-weight: 500;
  }

  .datacard-section-five .contact-text {
    font-size: 20px;
    font-weight: 500;
  }
}

/*********** Desktop End***********/
/************ Mobile *************/
@media (max-width: 992px) {
  .service-footer-text {
    font-size: 1.0rem;
    opacity: 0.9;
  }

  .sustaincard-section .text-one {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }

  .sustaincard-section .sustain-card-img {
    width: 50px;
    height: 50px;
  }

  .customercard-section .text-one {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }

  .customercard-section .customer-card-img {
    width: 100%;
    height: 100%;
  }

  .service-footer-img {
    width: 100%;
    height: 100%;
  }

  .datacard-section .text-one {
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }

  .datacard-section-one .data-card-blur {
    width: 300px;
    height: 300px;
    filter: blur(200px);
    z-index: 0;
    position: absolute;
    border-radius: 50%;
  }

  .sustaincard-section-five .contact-text {
    font-size: 14px;
    font-weight: 500;
  }

  .customercard-section-five .contact-text {
    font-size: 14px;
    font-weight: 500;
  }

  .datacard-section-five .contact-text {
    font-size: 14px;
    font-weight: 500;
  }

}

/************ Mobile End ************/
/**************** Tablet ********************/
@media (min-width:768px) and (max-width: 991px) {
  .datacard-section-one .datacard-img {
    height: 100%;
    width: 100%;
  }
}

/**************** Tablet End ********************/
/************  karthick responsive End **********/
/* sustaincard-section */
.sustaincard-section .card {
  background-color: transparent;
  color: white;
  border-radius: 0px;
}

.sustaincard-section-one {
  background-image: url("../images/servies-images/sustainservice-bg-img.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.sustaincard-section .sustain-icon {
  padding: 10px;
  font-size: 60px;
  color: #2af59873;
  box-shadow: inset 0 0 10px;
  background: transparent;
  border-radius: 50%;
}

.sustaincard-section-two .top-border {
  border-top: 5px solid #2af59873;
}

.sustaincard-section-two {
  background-color: black;
}

.sustaincard-section-three .card {
  border: none;
}

.sustaincard-section-three {
  background-color: rgba(46, 46, 46, 0.199);
}

.sustaincard-section-four {
  background-color: rgba(46, 46, 46, 0.199);
}

.sustaincard-section-one .row:hover {
  .card-text-two {
    opacity: 1;
  }
}

.sustaincard-section-two .card:hover {
  .card-text-two {
    opacity: 1;
  }
}

.sustaincard-section-three .card:hover {
  .card-text-two {
    opacity: 1;
  }
}

/* sustaincard-section End*/
/* customer Experience */
.customercard-section-one .card {
  background-color: rgb(0 10 30 / 46%);
}

.customercard-section-one .customer-icon {
  border-bottom: 2px solid white;
  border-radius: 10px;
  border-top: 2px solid white;
  color: white;
  font-size: 50px;
  padding: 7px;
}

.customercard-section-one .card:hover {
  .customer-icon {
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to bottom, white, grey) 1;
    font-size: 50px;
    padding: 7px;
  }

  .card-text-two {
    opacity: 1;
  }
}

.customercard-section-three {
  background-image: url("../images/servies-images/customerexperience/customerbgimg.webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.customercard-section-three .customercard-heading {}

.customercard-section-three .customer-arrow {
  font-size: 40px;

}

.customercard-section-two .card:hover {
  .text-ho {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .card-hide {
    display: none;
  }

  .customercard-section-three .row-reverse {
    flex-direction: column-reverse;
  }

  .datacard-section .row-reverse {
    flex-direction: column-reverse;
  }
}

.customercard-section-three .card-text-two {
  opacity: 1;
}

.customercard-section-three .row:hover {
  .text-ho {}
}

/* customer Experience End*/
/******** DataAnalytics ************/
.datacard-section-one .card:hover {
  .text-ho {
    opacity: 1;
  }
}

.datacard-section-two .card {
  background-color: rgba(46, 46, 46, 0.199);
  color: white;
  border: none;
}

.datacard-section-two .datacard-img {
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
}

.datacard-section-two .card:hover {
  .text-ho {
    opacity: 1;
  }
}

.datacard-section-three .card:hover {
  .text-ho {
    opacity: 1;
  }
}

/******** DataAnalytics End************/
/*====================  Karthick End=======================*/
/* thankyou */


.thankyou-tick-icon {
  font-size: 40px;
  padding: 5px 5px;
  color: white;
  background: #00B881;
  border-radius: 50%;
  border: 1px solid #06df9e;
}

.thank-text {
  font-size: 30px;
  font-weight: 600;
}

.visit-button {
  border: 1px solid #f9f9f9;
  padding: 5px 5px;
  font-size: 17px;
  background: #ffffff;
  border-radius: 6px;
  color: black;
  width: 180px;
}

.visit-button:hover {
  background: #bbbaba;
}

.thankyou-section .thank-sub-card .card {
  background: transparent;
  color: white;
  border: 1px solid rgba(128, 128, 128, 0.438);
}

.thankyou-section .card {
  background: rgba(255, 255, 255, 0.064);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.156);
  color: white;
}

.social-icons {
  font-size: 24px;
  border: 1px solid gray;
  padding: 7px 6px;
  border-radius: 50%;
  align-items: center;
  display: flex;
}

@media all and (min-width: 992px) {
  .thankyou-section {
    height: 650px;
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url("../imgs/ThankYou.webp");
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 991px) {
  .thankyou-section {
    height: 800px;
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url("../imgs/ThankYou.webp");
    background-size: cover;
    background-position: center;
    margin-top: 50px;
  }
  
}

.animated-button {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 3px 0px;
  border: 4px solid;
  border-color: transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: rgb(255, 158, 64);
  box-shadow: 0 0 0 2px rgb(255, 158, 64);
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  width: 170px;
}

.animated-button svg {
  width: 24px;
  fill: rgb(255, 158, 64);
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .arr-1 {
  right: 16px;
}

.animated-button .arr-2 {
  left: -25%;
}

.animated-button .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: rgba(255, 160, 64, 0.805);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.animated-button:hover {
  box-shadow: 0 0 0 12px transparent;
  color: #212121;
  border-radius: 12px;
  /* height: 45px; */
}

.animated-button:hover .arr-1 {
  right: -25%;
}

.animated-button:hover .arr-2 {
  left: 16px;
}

.animated-button:hover .text {
  transform: translateX(12px);
}

.animated-button:hover svg {
  fill: #212121;
}

/* .animated-button:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px rgba(255, 160, 64, 0.73);
} */

.animated-button:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;
}

/* .back-img-color{
  background-color: rgba(128, 128, 128, 0.466);
  height: 75vh;
  align-items: center;
} */

.mail-font {
  font-size: 13px;
}

.mail-under {
  text-decoration: underline;
}

.mail-under:hover {
  color: rgba(255, 160, 64, 0.805);
}

.mining-banner-section .sustaincard-section-one {
  background-image: none;
}

.oil-img {
  width: 80px;
}

.oilgas-banner-section .automotive-new-text-2 {
  box-shadow: inset 0 0 7px 5px rgb(27 169 174 / 12%),
    inset 0 2px 1px 1px rgba(0, 0, 0, 0.9),
    inset 0 -2px 1px rgba(0, 0, 0, 0.25);
}

.insurance-banner-section .sustaincard-section-three {
  background-color: transparent;
}

.insurance-banner-section .sustaincard-section-two .top-border {
  border-top: 3px solid rgb(236 232 159 / 38%);
}

.mining-banner-section .retail-banner-section-three .card-back-re1 {
  background-image: none;
}

.mining-banner-section .retail-banner-section-three .card-back-re2 {
  background-image: none;
}

.mining-banner-section .retail-banner-section-three .card-back-re3 {
  background-image: none;
}

.informationservice .automotive-new-design-2 .info-img {
  width: 75px;
  height: 75px;
  padding: 10px;
}
.engineeringprocurement-banner-section .aerospace-new-design-1{
  background-image: none;
}

.agriicon{
  width: 80px;
  height: auto;
}

.education-banner-section .con-icon-top{
    border-top: 1px solid rgba(235, 235, 235, 0.65);
    border-right: 1px solid rgba(248, 248, 248, 0.748);
    background-image: radial-gradient(circle farthest-corner at 0.2% 0.5%, #000000 30.7%, #262727 70.7%);
}

.hightechnology-banner-section .sustaincard-section-three {
background-color: transparent;
}
/* 
.wastemanagement-section .con-img-one{
  width: 140px;
  height: auto;
} */

/* @media (min-width: 992px) and (max-width: 1024px) {
  
  }
} */
 .place-size-hidden{
  overflow: hidden;
}
.contact-banner-section #rfs-btn {
  border: none;
  padding: 0px 0px;
  font-size: 13px !important;
}

.footer-section #rfs-btn{
  border: none;
  font-size: 13px !important;
  overflow: hidden;
}

.ReactFlagsSelect-module_fullWidthOptions__1XeR6{
  color: black;
 
}

/* #floatingInput {
  backdrop-filter:none;
  background-color: white !important;
} */

.from-flag{
  border: 1px solid #ffffff1c;
  border-radius: 6px;
  padding: 7px 0px;
  color: white;
  font-size: 14px;
  background: #3a3a3a1c;
}

.ReactFlagsSelect-module_selectBtn__19wW7{
  color: white !important;
}

.react-tel-input .selected-flag{
  padding: 0px 0px 0px 19px !important;
}

.react-tel-input .country-list {
  width: 235px !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
  background-image: url("../images/form-select-arrow.webp") !important;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  width: 16px !important;
  height: 10px !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
}

/* .contact-section-two .social-contact-section{
  align-items: center;
  background: #21202091;
  box-shadow: 0 12px 42px -4px rgba(24,39,75,.12), 0 8px 18px -6px rgba(24,39,75,.12);
  display: flex;
  flex-direction: column;
  gap: 19px;
  justify-content: center;
  padding: 10px 0px 0px 0px;
  border-radius: 20px;
} */


.contact-social-icon{
  font-size: 45px;
  background-color: #57575738;
  border: 1px solid #57575738;
  padding:5px 8px;
  border-radius: 25px;
}
.contact-social-text{
  font-size: 18px;
  transform: skewX(11deg);
  border: 1px solid #57575738;
  padding: 5px 57px;
  margin-top: 13px;
  margin-left: -20px;
  width: 200px;
  border-radius: 20px;
}



.utilities-img{
  width: 60px;
  height: auto;
}

.utilities-bammer-section .customercard-section-one .card{
  background-color: transparent !important;
}

.financial-background{
  background-image: url("../images/icon/financial\ services/financial services.webp");
}

.fin-icon-img{
  width: 70px;
  height: auto;
}

.private-equality-background{
  background-image: url("../images/icon/private equity/private equity landing banner.webp");
}

.professional-background{
  background-image: url("../images/icon/professional services/professional-services landing banner.webp");
}
.waste-management-background{
  background-image: url("../images/icon/waste management/waste management.webp");
}
.retail-background-image{
  background-image: url("../images/icon/retail/retail\ landing\ banner.webp");;
}
.consumer-background-image{
  background-image: url("../images/icon/consumer\ packaged\ goods/consumer-packaged-goods landing banner.webp");;
}
.logistic-background-image{
  background-image: url("../images/icon/logistics\ and\ distribution/logistics-and-distribution landing banner.webp") ;
}
.infrastructure-background{
  background-image: url("../images/servies-images/Infrastructure And Cloud Services webp/images/landing banner copy.webp") ;;
}
.network-background{
  background-image: url("../images/servies-images/Network\ Services\ webp/landing banner copy.webp") ;
}
.net-icon-img{
  width: 85px;
  height: auto;
}

.telecom-background{
  background-image: url("../images/servies-images/Telecom\ Product\ Development\ webp/images/landing banner copy.webp") ;
}
.TelecomProductDevelopment .sustaincard-section-three{
  background-color: transparent;
}
.cybersecurity-background{
  background-image: url("../images/servies-images/Cybersecurity\ Solutions\ webp/image/landing banner copy.webp") ;
}

.enterprise-background{
  background-image: url("../images/servies-images/Enterprise\ Of\ The\ Future\ Services\ webp/images2/landing banner copy.webp") ;
}
.intelligent-background{
  background-image: url("../images/servies-images/intelligent\ Automation\ Solutions\ webp/images/landing banner copy.webp") ;
}
.intel-icon-img{
  width: 100px;
  height: auto;
}
.oracle-background{
  background-image: url("../images/servies-images/Oracle\ Services\ webp/images/landing banner copy.webp") ;
}

.metaverse-background{
  background-image: url("../images/servies-images/metaverse\ development\ webp/images/landing banner copy.webp") ;
}
.oracle-icon-img{
  width: 83px;
  height: auto;
}
.dapp-background{
  background-image: url("../images/servies-images/DApp\ And\ DeFi\ Development\ webp/images/landing abnner copy.webp") ;
}
.dapp-icon-img{
  width: 90px;
  height: auto;
}
.smartcontract-background{
  background-image: url("../images/servies-images/smart\ contract\ webp/images/landing abnner copy 2.webp") ;
}
.smart-icon-img{
  width: 130px;
  height: auto;
}
.blockchain-background{
  background-image: url("../images/servies-images/Blockchain\ And\ Blockchain\ Application\ webp/image/landing banner copy.webp") ;
}
.block-icon-img{
  width: 90px;
  height: auto;
}
.artificial-background{
  background-image: url("../images/servies-images/Artificial\ Intelligence/images/landing banner copy.webp") ;
}
.arti1-icon-img{
  width: 100px;
  height: auto;
}
.testing-background{
  background-image: url("../images/servies-images/testing\ services/images/landing banner copy 2.webp") ;
}
.test-icon-img{
  width: 80px;
  height: auto;
}

.integrated-background{
  background-image: url("../images/servies-images/Integrated\ Engineering\ Solutions/images/landing banner copy.webp") ;
}
.performance-background{
  background-image: url("../images/servies-images/Performance Engineering/images (4)/landing banner copy 2.webp") ;
}
.digitalsuply-background{
  background-image: url("../images/servies-images/Digital\ Supply\ Chain/images/landing banner.webp") ;
}
.digi-icon-img{
  width: 90px;
  height: auto;
}
.businessprocess-background{
  background-image: url("../images/servies-images/Business\ Process\ Services/images/landing banner copy 2.webp") ;
}
.busi-icon-img{
  width: 80px;
}
.businessexcellence-img{
  background-image: url("../images/servies-images/Business\ Excellence\ Services/images/landing banner copy.webp") ;
}
.busiex-icon-img{
  width: 120px;
  height: auto;
}
.experience-background{
  background-image: url("../images/servies-images/Experience\ Design\ Service/images/landing banner.webp") ;
}
.sap-background{
  background-image: url("../images/servies-images/sap\ solution/images/landing banner copy 2.webp");
}
.dataanalytics-background{
  background-image: url("../images/servies-images/lastthreebanner/Data\ Analytics.webp")
}
.customer-background{
  background-image: url("../images/servies-images/lastthreebanner/Customer\ Experience\ landing\ banner.webp")
}
.sustainability-background{
  background-image: url("../images/servies-images/lastthreebanner/Sustainability\ As\ A\ Service.webp")
}
.logo-img-upstrox{
  width: 100px;

}

@media only screen and (min-width: 800px) and (max-width: 1024px){
  .footer-new-bg-section-inner-1 {
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 100px;
    padding-right: 25px;
    background-color: #000000c5;
}
.logi-img {
  width: 140%;
  height: auto;
}
}
.footer-section .form-floating>.form-select {
 padding-top: 10px ;
}

.infra-icon-img{
    border: 1px solid rgb(61 178 193 / 46%);
    border-radius: 50%;
    padding: 5px 5px;
    background: rgb(0,31,35)
}
.consum-img{
  width: 130px;
  height: auto;
}

.top-blog-section-one{
  height: 80vh;
  padding-top: 100px;
  background-color: rgb(60 31 1 / 59%);
}

.blog-text-two{
  font-size: 14px;
  line-height: 1.6;
  opacity: 0.8;
}
.blog-section-one .top-new-ups-height {
  height: 50vh;
}
.blog-button-read{
  font-size: 14px;
  border-radius: 20px;
  padding: 8px 25px;
  font-weight: 600;
  background: linear-gradient(270deg,#e39254,#f50);
}
.blog-section-one .swiper-pagination-bullet{
  width: 70px;
  border-radius: 15px;
  background: gray;
}
.blog-section-one .swiper-pagination-bullet-active{
  background: rgba(255, 85, 0, 0.911);
}

/* .blog-section-two{
  background: #f5f8fb5b;
} */
.under-line-title{
  height: 2px;
  max-width: 90px;
  display: block;
  content: "";
  background: rgb(255, 137, 69);
  margin: -6px auto 0;
}

/* .blog-section-two .list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: 1px;
  border-top-right-radius: 0;
}
.blog-section-two .list-group-item:first-child {
  border-top-left-radius: 1px;
  border-top-right-radius: inherit;
}
.blog-section-two .list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: 1px;
  border-bottom-left-radius: 0;
}
.blog-section-two .list-group-item:last-child {
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: inherit;
} */
.list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius:11px;
  border-top-right-radius: 11px;
}
.list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: 11px;
  border-bottom-left-radius: 11px;
}
.blog-section-one{
  background-image: url("../images/Blog/banner-blog.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.blog-section-three .list-group-item {
background: transparent;
border: none;
color: white;
display: flex;

}
.trend-section{
  border: 1px solid rgb(19, 18, 18);
  padding: 15px 2px;
  border-radius: 12px;
}
.blog-text-3{
  font-size: 17px;
  opacity: .7;
}
.hire-button{
  border: 1px solid #e39254;
  padding: 5px 15px;
  border-radius: 20px;
  color: #dd5a0e;
}
.blog-section-three .accordion-button{
  background-color: rgb(8, 8, 8);
  border: 1px solid rgb(34, 32, 32);
   color: white;
}
.trend-section{
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}
.blog-section-three .accordion-body {
  background: rgb(24, 23, 23);
  width: 100%;
  overflow: scroll;
}
.blog-section-four .card{
  background: #0f0f0f;
  color: white;
  border: 1px solid #0f0f0f;
}
.read-button{
  border-bottom: 1px solid orangered;
}
/* .custom-nav-hover
 {
  padding: 5px 5px;
  border-bottom: 2px solid transparent ;
}
.custom-nav-hover  {
  border-bottom: 2px solid rgb(206, 108, 43);
  width: 20%;
  left: 6%;
}
.custom-nav-hover::after  {
  bottom: 3%;
  content: "";
  width:0%;
  position: absolute;
  background: #fff;
  transition: width .5s, left .5s;
} */
.read-line{
  height: 2px;
  max-width: 45px;
  display: block;
  content: "";
  background: rgb(255, 137, 69);
}
.bottom-border{
  border-bottom: 1px solid rgb(19, 19, 19);
}
.point-group{
  font-size: 30px;
  color: #e39254;
}
.blog-section-three .accordion-button:focus {
  box-shadow: none;
}
.blog-section-three .accordion-button:not(.collapsed){
  box-shadow: none;
}
.blog-section-three .inner-one{
width: 270px;
height: auto;
}
.link-text{
  text-decoration: underline;
  color: #ff7900;
}
.blog-section-four .card-title{
  font-size: 18px;
}
.blog-section-four .page-link {
  color: #ff7900;
  padding: 10px 25px;
}
.blog-section-four .card:hover {
  transform: scale(0.9);
}
@media (max-width: 991px) {
  .blog-section-two .list-group-item {
    background: rgb(255, 255, 255);
    border: 1px solid gray;
    border-radius: 11px;
    font-size: 11px;
    display: flex;
    align-items: center;
}
  
}
@media all and (min-width: 992px) {
  .blog-section-two .list-group-item{
    background: rgb(255, 255, 255);
    border: 1px solid gray;
    border-radius: 11px;
  }
}

/* ::-webkit-scrollbar {
  width: 10px;
  color: #000000;
  background-color: #000000;
} */
/* .mouse-add {
  overflow: hidden;
 
} */

.metaversedevelopment .industrialmanu-icon-1{
    width: 80px;
    height: auto;
    margin-bottom: 20px;
}